import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'global-settings',
  templateUrl: './global-settings.component.html',
  styleUrls: ['./global-settings.component.css']
})
export class GlobalSettingsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
