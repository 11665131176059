import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AcademicsServiceService } from '../academics-service.service';
import { AuthService } from '../auth/auth.service';
import { DataService } from '../data.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  hide = true;
  UserName:string;
  Password:string;
  otpno:any;
  constructor(private router: Router,private service:AcademicsServiceService,private auth:AuthService,private dataService:DataService,private http:HttpClient) { }

  ngOnInit(): void {
    localStorage.setItem('loginhomepage', "0" );
    localStorage.setItem('logintype', '' );
    this.service.getModuleControl(this.auth.schoolid).subscribe(data=>{
     for(var i=1;i<=10;i++)
     {
      var a1 = "Module_"+String(i);
      if(data[0][a1]==null)
      {

      }
      else
      {
        var a2 = data[0][a1];
        if(a2=="Academics")
        {
          this.auth.academicsflag=1;
                 }
                 if(a2=="Admission")
                 {
                   this.auth.admissionflag=1;
                 }
                 if(a2=="schoolmanagement")
                 {
                   this.auth.studentmanagementflag=1;
                 }
                 if(a2=="attendance")
                 {
                   this.auth.attendanceflag=1;
                 }
                 if(a2=="certificate")
                 {
                   this.auth.certificateflag=1;
                 }

                 if(a2=="employee")
                 {
                   this.auth.employeeflag=1;
                 }
                 if(a2=="fees")
                 {
                   this.auth.feesflag=1;
                 }
                 if(a2=="payroll")
                 {
                   this.auth.payrollflag=1;
                 }
                 if(a2=="reportcard")
                 {
                   this.auth.reportcardflag=1;
                 }
                 if(a2=="library")
                 {
                   this.auth.libraryflag=1;
                 }

      }
     }

    })
  }


  onEnter() {
   if(this.Password=="")
   {}
   else
   this.loginClick();
  }


  loginClick()
  {
    this.service.getLoginData(this.UserName,this.Password).subscribe(data=>{
      if(data.length==0)
      {
        alert("Wrong Credentials!");
      }
      else
      {

        localStorage.setItem('dashboardstatus',"0");

        if(data.length==1)
        {
          if(data[0]["Category"]=="Admin" || data[0]["Category"]=="Staff"  || data[0]["Category"]=="Teacher")
          {
           
            this.auth.logintype=data[0]["Category"];
           
            this.auth.referenceID=data[0]["RefNo"];
            localStorage.setItem('logintype', this.auth.logintype  );
            localStorage.setItem('referenceID', String(this.auth.referenceID)  );
            localStorage.setItem('username', data[0]["UserName"]);

           

            var mobileno = data[0]["MobileNo"];
            this.otpno = Math.random().toString().substring(2, 6)
            this.auth.loginotpno= "1234";
            localStorage.setItem('loginotpno', this.auth.loginotpno  );

            //this.sendOTP(this.otpno,mobileno); 
            this.router.navigate(['/loginotp']);

          }
          else
          {
          
            this.auth.academicsflag==0;
            this.auth.logintype=data[0]["Category"];
            this.auth.referenceID=data[0]["RefNo"];

            localStorage.setItem('academicsflag', "0");
            localStorage.setItem('logintype', this.auth.logintype  );
            localStorage.setItem('referenceID', String(this.auth.referenceID)  );
            localStorage.setItem('username', data[0]["UserName"]);



            var qstring = "Where Ref_no = "+ this.auth.referenceID +"";
            this.service.getGrDataByQuery(qstring).subscribe(data=>
              {
               
              
                  var val = {
                    ID:data[0]["ID"],
                    MEDIUM:data[0]["MEDIUM"],
                  SECTIONTYPE:data[0]["SECTIONTYPE"],
                  STD:data[0]["STD"],
                  DIV:data[0]["DIV"],
                  RNO:data[0]["RNO"],
                  GR_NO:data[0]["GR_NO"],
                  REF_NO:data[0]["REF_NO"],
                
                  L_NAME:data[0]["L_NAME"],
                  F_NAME:data[0]["F_NAME"],
                  M_NAME1:data[0]["M_NAME1"],
                  M_NAME2:data[0]["M_NAME2"],
                  SEX:data[0]["SEX"],
                  
                  UNAIDED:data[0]["UNAIDED"],
                  
                  BIRTH_DATE:data[0]["BIRTHDATE"],
                  
                  PHONE_1:data[0]["PHONE_1"],
                  PHONE_2:data[0]["PHONE_2"],
                  PHONE_3:data[0]["PHONE_3"],
                
                  
                  DATE_O_ADM:data[0]["DATE_O_AD"],
                  
                  DOA_CONSIDER:data[0]["DOA_CONSIDER"],
                  
                  RADD_1:data[0]["RADD_1"],
                  RADD_2:data[0]["RADD_2"],
                  RADD_3:data[0]["RADD_3"],
                  
                  BIRTH_PLACE:data[0]["BIRTH_PLACE"],
                  TALUKA:data[0]["TALUKA"],
                  DISTRICT:data[0]["DISTRICT"],
                  STATE:data[0]["STATE"],
                  COUNTRY:data[0]["COUNTRY"],
                    
                  
              
              
              
                  ST_CL_AD:data[0]["ST_CL_AD"],
                  ADMTYPE:data[0]["ADMTYPE"],
                  
                  FATHER_Q:data[0]["FATHER_Q"],
                  FATHER_O:data[0]["FATHER_O"],
                  MOTHER_Q:data[0]["MOTHER_Q"],
                  MOTHER_O:data[0]["MOTHER_O"],
                  
                  RELIGION:data[0]["RELIGION"],
                  CASTE:data[0]["CASTE"],
                  SUB_CASTE:data[0]["SUB_CASTE"],
                  BUS_ALLOW:data[0]["BUS_ALLOW"],
                  STOP_NAME:data[0]["STOP_NAME"],
                  BUS_JD:data[0]["BUS_JD"],
                  BUS_ED:data[0]["BUS_ED"],
              
              
              
                  UIDNO:data[0]["UIDNO"],
                  IDNO:data[0]["IDNO"],
                  HOUSE:data[0]["HOUSE"],
                  NATIONALITY:data[0]["NATIONALITY"],
                  MOTHER_TONGUE:data[0]["MOTHER_TONGUE"],
                  CLASS:data[0]["CLASS"],
              
                  BLOOD_GROUP:data[0]["BLOOD_GROUP"],
                  HEALTH_ISSUE:data[0]["HEALTH_ISSUE"],
              
                  LS_NAME:data[0]["LS_NAME"],
                  LS_PLACE:data[0]["LS_PLACE"]
                       
                  };
              

        
                
                this.dataService.gdata = val;
                localStorage.setItem('gdata', JSON.stringify(this.dataService.gdata)  );

                

                  this.auth.smflag=true
                  this.auth.smchildflag=false;
                  this.auth.fmflag=true;


                  this.auth.fmchequeverification=false;
                  this.auth.fmfeestructure=false;
                  this.auth.fmreports=false;
                  this.auth.prflag=false;
                  this.auth.loggedIn=true;
                  this.router.navigate(['adminlayout']);
              })
          }


        }
       

      }


  })

  
  }


loadweb()
{
  this.service.getLoginData(this.UserName,this.Password).subscribe(data=>{
      if(data.length==0)
      {
alert("Not Found.");
      }
      else
      {
alert("Fond");
      }


  })
  
  this.router.navigate(['adminlayout']);

}


sendOTP(otpvalue:any,mobileno:any): void {
  debugger
  const USER = 'rajeshg';
  const PASS = '456456';
  const mobiles = mobileno;

  const RECIPIENT = '+46701234567';
  const SENDER = '+46701234567';
  this.auth.loginmobile=String(mobileno);
  this.auth.loginotpno=otpvalue;
  const MESSAGE = 'Your OTP Login is '+otpvalue+'. ESNDSH';

  const pid  = "1301159430019830458"
  const tid = "1607100000000103592"
  const fid  = "2"
  const cid  = "0"
  const senderID = "ESNDSH"


  const url = `http://myhost:3000/cgi-bin/sendsms?User=${USER}&Pass=${PASS}&To=${RECIPIENT}&From=${SENDER}&Msg=${MESSAGE}`;

  
  const nurl=`https://49.50.67.32/smsapi/httpapi.jsp?username=${USER}&password=${PASS}&from=ESNDSH&to=${mobiles}&text=${MESSAGE}&coding=0&pe_id=${pid}&template_id=${tid}`;

  this.http.get(nurl, {responseType: 'text'}).subscribe(
    response => {
      
      console.log('Got: ' + response);
    },
    error => {
      switch (error.status) {
        case 400:
          console.log('Bad request.' + error.error);
          break;
        case 401:
          console.log(`Your credentials were rejected with HTTP status code ${error.status}. Check and try again.`);
          break;
        case 409:
          console.log('Rate limit problem: ' + error.error);
          break;
        default:
          console.log('Request failed with HTTP status code: ' + error.status);
          break;
      }
    }
  ); 

 
}

}

