import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-it-infrastructure',
  templateUrl: './it-infrastructure.component.html',
  styleUrls: ['./it-infrastructure.component.scss']
})
export class ItInfrastructureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    document.getElementById("featured-services").scrollIntoView();
  }

}
