
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms'
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { EmailService } from 'src/app/services/email.service';



@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
 otpno:any;
  model : any={};

  constructor(private router: Router,private http: HttpClient,private emailService:EmailService,private auth:AuthService) { }

  ngOnInit(): void {
  }
 login2(f: NgForm){    
  debugger
if(this.model.Email.includes("@") == true)
{
  this.otpno = (Math.floor(Math.random() * 10000) + 10000).toString().substring(1);
  this.auth.loginby="E";
  this.sendMail()

}
else
{

  this.otpno = (Math.floor(Math.random() * 10000) + 10000).toString().substring(1);
 // this.otpno="1234";
  this.auth.loginotpno=this.otpno;
  this.auth.loginmobile= this.model.Email;
  this.auth.loginby="M";
  this.sendOTP(this.otpno,this.model.Email); 
  
}

  if(f.valid)
    {
      this.router.navigate(['/otpverify']);
    }
    else{
      alert("Empty Required Data.");
    }


  
  }

  sendOTP(notpno:any,mobileno:any): void {
    debugger
    //this.auth.loginotpno=notpno;

    this.auth.loginuser = this.model.Email;
    const USER = 'rajeshg';
    const PASS = '456456';
    const mobiles = mobileno;
  
    const RECIPIENT = '+46701234567';
    const SENDER = '+46701234567';
    const MESSAGE = 'Your OTP Login is '+this.otpno+'. ESNDSH';
 
    const pid  = "1301159430019830458"
    const tid = "1607100000000103592"
    const fid  = "2"
    const cid  = "0"
    const senderID = "ESNDSH"
  
  
    const url = `http://myhost:3000/cgi-bin/sendsms?User=${USER}&Pass=${PASS}&To=${RECIPIENT}&From=${SENDER}&Msg=${MESSAGE}`;
  
    
    const nurl=`https://49.50.67.32/smsapi/httpapi.jsp?username=${USER}&password=${PASS}&from=ESNDSH&to=${mobiles}&text=${MESSAGE}&coding=0&pe_id=${pid}&template_id=${tid}`;
  
//alert(nurl);

     this.http.get(nurl, {responseType: 'text'}).subscribe(
      response => {
        
        console.log('Got: ' + response);
      },
      error => {
        switch (error.status) {
          case 400:
            console.log('Bad request.' + error.error);
            break;
          case 401:
            console.log(`Your credentials were rejected with HTTP status code ${error.status}. Check and try again.`);
            break;
          case 409:
            console.log('Rate limit problem: ' + error.error);
            break;
          default:
            console.log('Request failed with HTTP status code: ' + error.status);
            break;
        }
      }
    ); 
  }
  
   sendMail(){
    /* alert("jjj");
    let email  = this.model.Email;
    let reqObj = {
      email:email
    }
    this.emailService.sendMessage(reqObj).subscribe(data=>{
      console.log(data);
    },
    err=> {
      console.log(err);
      alert(err);
    }
    
    ) */

    this.auth.loginotpno=this.otpno;
    this.auth.loginuser = this.model.Email;
   
    let user = {
      name: "",
      email: this.model.Email,
      message: this.otpno
    }
    this.emailService.sendMessage(user).subscribe(
      data => {
        let res:any = data; 
        console.log(
          `👏 > 👏 > 👏 > 👏 ${user.name} is successfully register and mail has been sent and the message id is ${res.messageId}`
        );
      },
      err => {
        console.log(err);
       
      },() => {
        
      }
    );

  }
}
