import { Component, OnInit } from '@angular/core';
import { DataService } from './../../data.service';

@Component({
  selector: 'session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.css']
})
export class SessionComponent implements OnInit {

  constructor(private DataService:DataService) { }

  ngOnInit(): void {
    this.DataService.maintitle="Academics/Session";
  }

}
