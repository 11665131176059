import { HttpClient } from '@angular/common/http';

import { Router } from '@angular/router';
import { Component, ElementRef, OnInit, Pipe } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AcademicsServiceService } from 'src/app/academics-service.service';
import { DataService } from 'src/app/data.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'loginotp',
  templateUrl: './loginotp.component.html',
  styleUrls: ['./loginotp.component.css']
})
export class LoginotpComponent implements OnInit {
  private elementRef: ElementRef
  OTP1:string;
  constructor(private router:Router,private auth:AuthService,private dataService:DataService,private service:AcademicsServiceService,private http:HttpClient) { 
    
  }

  ngOnInit(): void {

 
  }

  ngAfterViewInit(){
    this.elementRef.nativeElement.focus();
}
  login4(f: NgForm){    
    if(f.valid)
    {
      if(this.OTP1==this.auth.loginotpno)
      {
      
       
      
     this.auth.loggedIn=true;
     this.auth.isUserLoggedIn();
     this.auth.fmchequeverification=false;
          this.auth.fmfeestructure=false;
          this.auth.fmreports=false;
          this.auth.prflag=false;
          localStorage.setItem("academicsflag","0");

          localStorage.setItem('showdata',"1");
        if(this.auth.logintype=="Teacher")
        {
         
          this.service.getEmployeeDataByID(this.auth.referenceID).subscribe(data=>
            {
              
                this.dataService.edata=data;
                this.auth.smflag=true
                this.auth.smchildflag=false;
                this.auth.fmflag=false
                this.auth.fmchildflag=false;
                localStorage.setItem('edata', JSON.stringify(this.dataService.edata)  );
            })
        }
else
{
  if(this.auth.logintype=="Admin")
  {
    localStorage.setItem("academicsflag","1");

    this.auth.feesmaster=1;
    this.auth.academicsmaster=1;
    this.auth.admissionmaster=1;
    this.auth.reportcardmaster=1;
    this.auth.certificatemaster=1;
    this.auth.attendancemaster=1;
    this.auth.payrollmaster=1;
    this.auth.librarymaster=1;


    this.service.getEmployeeDataByID(this.auth.referenceID).subscribe(data=>
      {
        
          this.dataService.edata=data;
          localStorage.setItem('edata', JSON.stringify(this.dataService.edata)  );
       
          //localStorage.setItem("username",this.dataService.edata[0]["EmployeeName"]);

          this.auth.smflag=true
          this.auth.smchildflag=true;
          this.auth.fmflag=true;
          this.auth.fmchildflag=true;

          this.auth.fmchequeverification=true;
          this.auth.fmfeestructure=true;
          this.auth.fmreports=true;
          this.auth.prflag=true;
      })
  }
}

        this.router.navigate(['adminlayout']);
      }
      else
      {
        alert("Wrong OTP");
      }
      
    }
    else{
      alert("Empty Required Data.");
    }
  }


  ProcessOTP()
  {
    var otpno = Math.random().toString().substring(2, 6)
    //this.auth.loginotpno= "1234";
    this.sendOTP(otpno,this.auth.loginmobile); 
  }


  sendOTP(otpvalue:any,mobileno:any): void {
    debugger
    const USER = 'rajeshg';
    const PASS = '456456';
    const mobiles = mobileno;
  
    const RECIPIENT = '+46701234567';
    const SENDER = '+46701234567';
    this.auth.loginmobile=String(mobileno);
    this.auth.loginotpno=otpvalue;
    const MESSAGE = 'Your OTP Login is '+otpvalue+'. ESNDSH';
  
    const pid  = "1301159430019830458"
    const tid = "1607100000000103592"
    const fid  = "2"
    const cid  = "0"
    const senderID = "ESNDSH"
  
  
    const url = `http://myhost:3000/cgi-bin/sendsms?User=${USER}&Pass=${PASS}&To=${RECIPIENT}&From=${SENDER}&Msg=${MESSAGE}`;
  
    
    const nurl=`https://49.50.67.32/smsapi/httpapi.jsp?username=${USER}&password=${PASS}&from=ESNDSH&to=${mobiles}&text=${MESSAGE}&coding=0&pe_id=${pid}&template_id=${tid}`;
  
    this.http.get(nurl, {responseType: 'text'}).subscribe(
      response => {
        
        console.log('Got: ' + response);
      },
      error => {
        switch (error.status) {
          case 400:
            console.log('Bad request.' + error.error);
            break;
          case 401:
            console.log(`Your credentials were rejected with HTTP status code ${error.status}. Check and try again.`);
            break;
          case 409:
            console.log('Rate limit problem: ' + error.error);
            break;
          default:
            console.log('Request failed with HTTP status code: ' + error.status);
            break;
        }
      }
    ); 
  
   
  }
}
