import { AddEditCalendarMasterComponent } from './academics/calendar-master/add-edit-calendar-master/add-edit-calendar-master.component';
import { ShowCalendarMasterComponent } from './academics/calendar-master/show-calendar-master/show-calendar-master.component';
import { CalendarMasterComponent } from './academics/calendar-master/calendar-master.component';
import { AddEditSchoolinfoComponent } from './academics/schoolinfo/add-edit-schoolinfo/add-edit-schoolinfo.component';
import { ShowSchoolinfoComponent } from './academics/schoolinfo/show-schoolinfo/show-schoolinfo.component';
import { SchoolinfoComponent } from './academics/schoolinfo/schoolinfo.component';
import { SessionComponent } from './academics/session/session.component';
import { AcademicsComponent } from './academics/academics.component';
import { CustomMaterialModule } from './material.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SpinnerComponent } from './shared/spinner/spinner.component';
import { ContentAnimateDirective } from './shared/directives/content-animate.directive';

import { LoginFormComponent } from './login-form/login-form.component';
import { HomePageComponent } from './home-page/home-page.component';

import {HttpClientModule} from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ForgotpasswordComponent } from './login-form/forgotpassword/forgotpassword.component';
import { ChangepasswordComponent } from './login-form/forgotpassword/changepassword/changepassword.component';
import { FormatTimePipe, OtpverifyComponent } from './login-form/forgotpassword/otpverify/otpverify.component';
import { ResendotpformComponent } from './login-form/forgotpassword/resendotpform/resendotpform.component';
import { LoginotpComponent } from './login-form/forgotpassword/loginotp/loginotp.component';
import { FullCalendarModule } from '@fullcalendar/angular'; 
import dayGridPlugin from '@fullcalendar/daygrid'; 
import interactionPlugin from '@fullcalendar/interaction'; 
import { ShowSessionComponent } from './academics/session/show-session/show-session.component';
import { AddEditSessionComponent } from './academics/session/add-edit-session/add-edit-session.component';
import { ClassinfoComponent } from './academics/classinfo/classinfo.component';
import { AgGridModule } from 'ag-grid-angular';
import { BtnCellRenderer } from './button-cell-renderer.component';

import { NgxPaginationModule } from 'ngx-pagination';
import { SubjectMasterComponent } from './academics/subject-master/subject-master.component';
import { AuthService } from './auth/auth.service';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { GlobalSettingsComponent } from './academics/global-settings/global-settings.component';
import { TimetableComponent } from './academics/timetable/timetable.component';
import { ToastrModule } from 'ngx-toastr';
import { NoticeBoardComponent } from './academics/notice-board/notice-board.component';
import { ShowNoticesComponent } from './academics/notice-board/show-notices/show-notices.component';
import { AddEditNoticesComponent } from './academics/notice-board/add-edit-notices/add-edit-notices.component';
import { AuthGuard } from './auth/auth.guard';
import { SofwaredevelopmentComponent } from './sofwaredevelopment/sofwaredevelopment.component';
import { ItInfrastructureComponent } from './it-infrastructure/it-infrastructure.component';
import { NetworkSystemIntegrationComponent } from './network-system-integration/network-system-integration.component';
import { NetworkSecurityComponent } from './network-security/network-security.component';
import { DatabaseSolutionComponent } from './database-solution/database-solution.component';
import { DatacenterSolutionComponent } from './datacenter-solution/datacenter-solution.component';
import { MainPageComponent } from './main-page/main-page.component';


FullCalendarModule.registerPlugins([ 
  interactionPlugin,
  dayGridPlugin
]);
@NgModule({
  declarations: [
    AppComponent,

    NavbarComponent,
    SidebarComponent,
    FooterComponent,
  
  
    SpinnerComponent,
    ContentAnimateDirective,
    LoginFormComponent,
    HomePageComponent,
    ForgotpasswordComponent,
    ChangepasswordComponent,
    OtpverifyComponent,
    ResendotpformComponent,
    FormatTimePipe,
      LoginotpComponent,
    
      AcademicsComponent,
      SessionComponent,
      ShowSessionComponent,
      AddEditSessionComponent,
      GlobalSettingsComponent,
   TimetableComponent,
      SchoolinfoComponent,
      ShowSchoolinfoComponent,
      AddEditSchoolinfoComponent,
SubjectMasterComponent,
CalendarMasterComponent,
ShowCalendarMasterComponent,
AddEditCalendarMasterComponent,

         ClassinfoComponent,
       
    
SubjectMasterComponent,
   NoticeBoardComponent,
   ShowNoticesComponent,
   AddEditNoticesComponent,
  
SofwaredevelopmentComponent,
ItInfrastructureComponent,
NetworkSystemIntegrationComponent,
NetworkSecurityComponent,
DatabaseSolutionComponent,
DatacenterSolutionComponent,
MainPageComponent,



      
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    CustomMaterialModule,
    FlexLayoutModule,
    FullCalendarModule ,
    NgxPaginationModule,
    Ng2SearchPipeModule,

  
   /*  ToastrModule.forRoot({
      toastClass: 'toast toast-bootstrap-compatibility-fix'
    }), */


    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-center-center',
      preventDuplicates: true,
    }),
    AgGridModule.withComponents([BtnCellRenderer]),
  
    
  ],
  providers: [ThemeService,AuthService,AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
