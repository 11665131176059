
import { LoginFormComponent } from './login-form/login-form.component';
import { HomePageComponent } from './home-page/home-page.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { ForgotpasswordComponent } from './login-form/forgotpassword/forgotpassword.component';
import { OtpverifyComponent } from './login-form/forgotpassword/otpverify/otpverify.component';
import { ChangepasswordComponent } from './login-form/forgotpassword/changepassword/changepassword.component';
import { AuthChangePwd } from './auth/auth.change.pwd';
import { LoginotpComponent } from './login-form/forgotpassword/loginotp/loginotp.component';
import { AcademicsComponent } from './academics/academics.component';
import { SessionComponent } from './academics/session/session.component';
import { SubjectMasterComponent } from './academics/subject-master/subject-master.component';
import { ClassinfoComponent } from './academics/classinfo/classinfo.component';
import { SchoolinfoComponent } from './academics/schoolinfo/schoolinfo.component';
import { CalendarMasterComponent } from './academics/calendar-master/calendar-master.component';
import { AuthGuard } from './auth/auth.guard';
import { GlobalSettingsComponent } from './academics/global-settings/global-settings.component';
import { TimetableComponent } from './academics/timetable/timetable.component';
import { NoticeBoardComponent } from './academics/notice-board/notice-board.component';
import { SofwaredevelopmentComponent } from './sofwaredevelopment/sofwaredevelopment.component';
import { ItInfrastructureComponent } from './it-infrastructure/it-infrastructure.component';
import { NetworkSystemIntegrationComponent } from './network-system-integration/network-system-integration.component';
import { NetworkSecurityComponent } from './network-security/network-security.component';
import { DatabaseSolutionComponent } from './database-solution/database-solution.component';
import { DatacenterSolutionComponent } from './datacenter-solution/datacenter-solution.component';
import { MainPageComponent } from './main-page/main-page.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import {TermsConditionsComponent} from './terms-conditions/terms-conditions.component';






const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomePageComponent,  
  children:[
  { path: 'softwaredevelopment', component: SofwaredevelopmentComponent},
  { path: 'itinfrastructure', component: ItInfrastructureComponent},
  { path: 'networksystem', component: NetworkSystemIntegrationComponent},
  { path: 'networksecurity', component: NetworkSecurityComponent},
  { path: 'databasesolution', component: DatabaseSolutionComponent},
  { path: 'datacenter', component: DatacenterSolutionComponent},
  { path: 'privacypolicy', component: PrivacyPolicyComponent},
  { path: 'termsandconditions', component: TermsConditionsComponent},
  { path: 'mainpage', component: MainPageComponent},
  ]},
  { path: 'loginform', component: LoginFormComponent},
    {
    path: 'forgotpassword',
   component: ForgotpasswordComponent,
    pathMatch: 'full',
  },
  {
    path: 'otpverify',
   component: OtpverifyComponent,
    pathMatch: 'full',
  },
  {
    path: 'changepassword',
    canActivate: [AuthChangePwd],
   component: ChangepasswordComponent,
    pathMatch: 'full',
  }
  ,

  {
    path: 'loginotp',
   component: LoginotpComponent,
    pathMatch: 'full',
  },
  

 /*  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'basic-ui', loadChildren: () => import('./basic-ui/basic-ui.module').then(m => m.BasicUiModule) },
  { path: 'charts', loadChildren: () => import('./charts/charts.module').then(m => m.ChartsDemoModule) },
  { path: 'forms', loadChildren: () => import('./forms/form.module').then(m => m.FormModule) },
  { path: 'tables', loadChildren: () => import('./tables/tables.module').then(m => m.TablesModule) },
  { path: 'icons', loadChildren: () => import('./icons/icons.module').then(m => m.IconsModule) },
  { path: 'general-pages', loadChildren: () => import('./general-pages/general-pages.module').then(m => m.GeneralPagesModule) },
  { path: 'apps', loadChildren: () => import('./apps/apps.module').then(m => m.AppsModule) },
  { path: 'user-pages', loadChildren: () => import('./user-pages/user-pages.module').then(m => m.UserPagesModule) },
  { path: 'error-pages', loadChildren: () => import('./error-pages/error-pages.module').then(m => m.ErrorPagesModule) }, */
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy',useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
