import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-database-solution',
  templateUrl: './database-solution.component.html',
  styleUrls: ['./database-solution.component.scss']
})
export class DatabaseSolutionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    document.getElementById("featured-services").scrollIntoView();
  }

}
