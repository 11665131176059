import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { relativeTimeThreshold } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AcademicsServiceService } from 'src/app/academics-service.service';
import { DataService } from './../../data.service';

@Component({
  selector: 'subject-master',
  templateUrl: './subject-master.component.html',
  styleUrls: ['./subject-master.component.css']
})
export class SubjectMasterComponent implements OnInit {

  subjectdataList: Array<any> = [];
  awaitingClassList: Array<any> = [];
  editField: string;
  newdataitem: any;
  subjectdata;
ID:any;
       SubjectName:"";
      SubjectCode:"";
      PrintName:"";
      ColorString:"";
      alertmessage="";
      ModalTitle="";
  constructor(private service:AcademicsServiceService,private DataService:DataService,private modalService:NgbModal,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.DataService.maintitle="Academics/Subject Master";

    
    this.refreshSubjectDataList();
  }

  refreshSubjectDataList(){
    this.editField="";
    this.service.getSubjectDataList().subscribe(data=>{
      data = data.sort((a, b) => (a.ID > b.ID ? -1 : 1));

      this.subjectdataList=data;
    })
  }

  updateList() {

    //const editField = event.target.textContent;
    //this.classdataList[mid][property]= editField;

    var val = {ID:this.ID,
      SubjectName:this.SubjectName,
      SubjectCode:this.SubjectCode,
      PrintName:this.PrintName,
      ColorString:this.ColorString
             
     };

    this.service.updateSubjectData(val).subscribe(res=>{
      this.refreshSubjectDataList();
      this.modalService.dismissAll();
      this.alertmessage="Update Successfully.";
          this.showSuccess();
     
});



  }


  editClick(item: any,exampleModalContent){

   

       this.subjectdata=item;

      this.ID=this.subjectdata.ID;
      this.SubjectName=this.subjectdata.SubjectName,
      this.SubjectCode=this.subjectdata.SubjectCode,
      this.PrintName=this.subjectdata.PrintName,
      this.ColorString=this.subjectdata.ColorString


    this.ModalTitle = "Edit Subject";
    this.modalService.open( exampleModalContent ,{ centered: true, windowClass: 'modal-rounded' });
  }

  deleterecord() {
   
    
      this.service.deleteSubjectData(this.newdataitem.ID).subscribe(data=>
        {
         // alert(data.toString());
          this.refreshSubjectDataList();
          this.modalService.dismissAll();
          this.alertmessage="Delete Successfully.";
          this.showSuccess();
        });
  
   
  }

closeClick()
{
  this.modalService.dismissAll();
}

  deleteClick(item: any,smallModalContent){


    this.newdataitem=item;
    this.modalService.open( smallModalContent, {centered: true } );
  }
  

  add() {
    //if (this.awaitingClassList.length > 0) {
      //const person = this.awaitingClassList[0];

      var val = {ID:0,
        SubjectName:this.SubjectName,
        SubjectCode:this.SubjectCode,
        PrintName:this.PrintName,
        ColorString:this.ColorString
            
      };
        this.service.addSubjectData(val).subscribe(res=>{
          
          this.refreshSubjectDataList();
        
          this.modalService.dismissAll();

          this.alertmessage="Add Successfully.";
          this.showSuccess();
        });

      
      
   // }
  }

  
  addClick(exampleModalContent)
  {
    this.ID=0,
    this.SubjectName="",
    this.SubjectCode=  "",
    this.PrintName="",
    this.ColorString=""
    this.ModalTitle="Add Subject";
    this.modalService.open( exampleModalContent ,{ centered: true, windowClass: 'modal-rounded' });
  }


  changeValue(mid: number, property: string, event: any) {
   
    this.editField = event.target.textContent;
    //this.classdataList[mid][property] = this.editField;
  }


  showSuccess() {
    this.toastr.success(this.alertmessage, '');
 
  }

}
