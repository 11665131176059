import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'academics',
  templateUrl: './academics.component.html',
  styleUrls: ['./academics.component.css']
})
export class AcademicsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
