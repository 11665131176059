import { AuthService } from 'src/app/auth/auth.service';
import { Component, Inject, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavbarComponent implements OnInit {
  
  
  elem;
  fullscreen=1;
  public iconOnlyToggled = false;
  public sidebarToggled = false;
  refimage="";
  username="Rajesh Gawde";
 
  constructor(config: NgbDropdownConfig,private auth:AuthService,@Inject(DOCUMENT) private document: any) {
    config.placement = 'bottom-right';
    
  }

  ngOnInit() {
    this.elem = document.documentElement;
    this.refimage="assets/lexicon/photos/"+localStorage.getItem("referenceID")+".jpg";
    this.username=localStorage.getItem("username");
  }

  // toggle sidebar in small devices
  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  // toggle sidebar
  toggleSidebar() {
    let body = document.querySelector('body');
    if((!body.classList.contains('sidebar-toggle-display')) && (!body.classList.contains('sidebar-absolute'))) {
      this.iconOnlyToggled = !this.iconOnlyToggled;
      if(this.iconOnlyToggled) {
        body.classList.add('sidebar-icon-only');
      } else {
        body.classList.remove('sidebar-icon-only');
      }
    } else {
      this.sidebarToggled = !this.sidebarToggled;
      if(this.sidebarToggled) {
        body.classList.add('sidebar-hidden');
      } else {
        body.classList.remove('sidebar-hidden');
      }
    }
  }

  // toggle right sidebar
  // toggleRightSidebar() {
  //   document.querySelector('#right-sidebar').classList.toggle('open');
  // }


signout()
{
  localStorage.clear();

}

openFullscreen() {
  if (this.elem.requestFullscreen) {
    this.elem.requestFullscreen();
  } else if (this.elem.mozRequestFullScreen) {
    /* Firefox */
    this.elem.mozRequestFullScreen();
  } else if (this.elem.webkitRequestFullscreen) {
    /* Chrome, Safari and Opera */
    this.elem.webkitRequestFullscreen();
  } else if (this.elem.msRequestFullscreen) {
    /* IE/Edge */
    this.elem.msRequestFullscreen();
  }
  this.fullscreen=0;
}

closeFullscreen() {
  if (document.exitFullscreen) {
    this.document.exitFullscreen();
  } else if (this.document.mozCancelFullScreen) {
    /* Firefox */
    this.document.mozCancelFullScreen();
  } else if (this.document.webkitExitFullscreen) {
    /* Chrome, Safari and Opera */
    this.document.webkitExitFullscreen();
  } else if (this.document.msExitFullscreen) {
    /* IE/Edge */
    this.document.msExitFullscreen();
  }
  this.fullscreen=1;
}

routerchange()
{
  localStorage.setItem('loginhomepage', "1" );
}
}
