import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Component, OnInit, ViewChild } from '@angular/core';
import { AcademicsServiceService } from 'src/app/academics-service.service';


@Component({
  selector: 'show-session',
  templateUrl: './show-session.component.html',
  styleUrls: ['./show-session.component.css']
})
export class ShowSessionComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  constructor(private service:AcademicsServiceService,private modalService:NgbModal) { }
  SessionList: any[] = [];
  
  ModalTitle:string="";
  ActivateAddEditSessionComp:boolean=false;
  session:any;
newdataitem:any;
  ngOnInit(): void {
       this.refreshSessionList();
  }
  
  
  /*  openModal( exampleModalContent ) {
    this.modalService.open( exampleModalContent, { size : 'lg' } );
  } */

 /* openMediumModal( mediumModalContent ) {
    this.modalService.open( mediumModalContent );
  }

  openSmallModal( smallModalContent ) {
    this.modalService.open( smallModalContent, { size : 'sm' } );
  } */


  
  addClick(exampleModalContent){
    debugger
    this.session={
      ID:0,
      SessionName:"",
      SessionFrom:new Date(),
      SessionTo:new Date(),
      Active:false
    }
    this.ModalTitle="Add Session";
    this.ActivateAddEditSessionComp=true;
    this.modalService.open( exampleModalContent ,{ centered: true, windowClass: 'modal-rounded' });
  }
 

closeClick(){
  this.ActivateAddEditSessionComp=false;
  this.refreshSessionList();
  this.modalService.dismissAll();
}

onClose() {

  this.ActivateAddEditSessionComp=false;
  this.refreshSessionList(); 
  //this.closebutton.nativeElement.click();
  this.modalService.dismissAll();


}
editClick(item: any,exampleModalContent){

  this.session=item;
  this.ModalTitle = "Edit Session";
  this.ActivateAddEditSessionComp=true;
  this.modalService.open( exampleModalContent ,{ centered: true, windowClass: 'modal-rounded' });
}

deleterecord()
{
  this.service.deleteSession(this.newdataitem.ID).subscribe(data=>
    {
      
      this.refreshSessionList();
      this.modalService.dismissAll();
    });
}



  deleteClick(item: any,smallModalContent){
    this.newdataitem=item;
    this.modalService.open( smallModalContent, {centered: true } );



    /* if(confirm('Are you sure ??'))
    {
      this.service.deleteSession(item.ID).subscribe(data=>
        {
          alert(data.toString());
          this.refreshSessionList();

        });
    } */
   
    
  } 
  refreshSessionList(){
    
    this.service.getSessionList().subscribe(data=>{
      this.SessionList=data;
    })
  }
}

