import { AuthService } from './auth.service';
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate{
       constructor(private auth:AuthService,private router:Router){

    }
   /*  canActivate(route: ActivatedRouteSnapshot, 
        state: RouterStateSnapshot):  Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
      debugger
            return this.auth.isLoggedIn()
            .pipe(map((isloggedIn: any) => isloggedIn? true: this.router.parseUrl('adminlayout')))
    } */


    canActivate(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean|UrlTree {

if(!this.auth. isUserLoggedIn()) {
    alert('You are not allowed to view this page. You are redirected to login Page');
    
    this.router.navigate(["loginform"],{ queryParams: { retUrl: route.url} });
    return false;

    //var urlTree = this.router.createUrlTree(['login']);
    //return urlTree;
} 


return true;
}
}