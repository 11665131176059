import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms'
import { Router } from '@angular/router';
import { AcademicsServiceService } from 'src/app/academics-service.service';
import { AuthService } from 'src/app/auth/auth.service';


@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {
  model : any={};
  hide = true;
  hide1 = true;
  constructor(private router: Router,private service:AcademicsServiceService,private auth:AuthService) { }

  ngOnInit(): void {
  }

  login4(f: NgForm){    
    if(f.valid)
    {
    debugger

    if(this.model.PWord==this.model.CPWord)
    {
      var val = {
        UserName:this.auth.loginuser,
        Password:this.model.PWord,
        Status:this.auth.loginby
      }
      
      this.service.UpdateLoginData(val).subscribe(data=>{
        this.router.navigate(['/loginform']);
      });

    }
    else
    {
      alert("Confirm Password Not Matched.");
    }

     
    }
    else{
      alert("Empty Required Data.");
    }
  }


}
