import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {DOC_ORIENTATION, NgxImageCompressService} from 'ngx-image-compress';
import { Validators } from '@angular/forms';
import { AcademicsServiceService } from 'src/app/academics-service.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'add-edit-schoolinfo',
  templateUrl: './add-edit-schoolinfo.component.html',
  styleUrls: ['./add-edit-schoolinfo.component.css']
})
export class AddEditSchoolinfoComponent implements OnInit {
  @Input() schoolinfo:any;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  ID?: string ;
  MEDIUM?:string;
  SECTIONTYPE?:string;
  HEAD?:string;
  ADDRESS?:string;
  REGNO?:string;
  SchoolIDNo?:string;
  SchoolLogo?:string;
  PrincipalSign?:string;
  
  public message: string;
  public imagePath;
  imgURL: any;
  imgURL1: any;
  selectedFile;
// City Names
//Medium: Array<any> = [];

items = [
  { content: "one" },
  { content: "two" },
  { content: "three" },
  { content: "four" }
];



dataList: Array<any> = [];
Medium: any = ['E', 'H', 'M', 'G'];
Section: any = ['K', 'P', 'S', 'C', 'D'];
sectionName:any;
mediumName: any;
powers = ['E', 'H',
'M', 'G'];
levelNum:number;
levels:Array<any> = [
    {num: 0, name: "E"},
    {num: 1, name: "H"},
    {num: 2, name: "M"}
];

selectedLevel = this.levels[0];
selectedLevelCustomCompare = {num: 1, name: "E"}
  constructor(private service: AcademicsServiceService,private sanitizer:DomSanitizer,private imageCompress: NgxImageCompressService,private toastr: ToastrService) {
    

   }

  ngOnInit(): void {
   
    this.ID=this.schoolinfo.ID;
    this.MEDIUM=this.schoolinfo.MEDIUM;
    this.SECTIONTYPE=this.schoolinfo.SECTIONTYPE;
    this.HEAD=this.schoolinfo.HEAD;
    this.ADDRESS=this.schoolinfo.ADDRESS;
    this.REGNO=this.schoolinfo.REGNO;
     this.SchoolIDNo=this.schoolinfo.SchoolIDNo;
     //this.SchoolLogo=this.schoolinfo.SchoolLogo;
     this.SchoolLogo = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg=="
     this.SchoolLogo = "data:image/png;base64,"+ this.schoolinfo.SchoolLogo
    // this.SchoolLogo=this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64, $this.schoolinfo.SchoolLogo');
    this.PrincipalSign="data:image/png;base64,"+ this.schoolinfo.PrincipalSign; 
   // this.selectedLevelCustomCompare = {num: 1, name: "E"};

  }

  changeMedium(e) {
    this.mediumName=e.target.value
      onlySelf: true
    
    
    
  }

  changeSection(e) {
    this.sectionName=e.target.value
      onlySelf: true
    
    
    
  }


  onFileChanged(event) {
    
    this.selectedFile = event.target.files[0]
    const reader = new FileReader();
    reader.readAsDataURL(this.selectedFile);
    reader.onload = () => {
        console.log(reader.result);
        this.imgURL=reader.result;


        this.imageCompress.compressFile(this.imgURL, 0, 75, 50).then(
          result => {
            console.log(result);
            //this.imgResultAfterCompress = result;
            this.SchoolLogo=result;
            console.warn('Size in bytes is now:', this.imageCompress.byteCount(result));
          }
        );
        //this.SchoolLogo=this.imgURL;
       
    };
  }

  onFileChanged1(event) {
    
    this.selectedFile = event.target.files[0]
    const reader = new FileReader();
    reader.readAsDataURL(this.selectedFile);
    reader.onload = () => {
        console.log(reader.result);
        this.imgURL1=reader.result;

        
        this.PrincipalSign=this.imgURL1;
       
    };
  }

  
  addSchoolInfo(){
    
     var val = {ID:this.ID,
     MEDIUM:this.MEDIUM,
     SECTIONTYPE:this.SECTIONTYPE,
     HEAD:this.HEAD,
     ADDRESS:this.ADDRESS,
     REGNO:this.REGNO,
     SchoolIDNo:this.SchoolIDNo,
     SchoolLogo:this.SchoolLogo.split(',')[1],
     PrincipalSign:this.PrincipalSign.split(',')[1]
        
         
   };
     this.service.addSchoolInfo(val).subscribe(res=>{
       //alert(res.toString());
       this.onModalClose();
       this.showSuccess();

     });
     }
     

     updateSchoolInfo(){
      
      
      var val = {ID:this.ID,
        MEDIUM:this.MEDIUM,
        SECTIONTYPE:this.SECTIONTYPE,
        HEAD:this.HEAD,
        ADDRESS:this.ADDRESS,
        REGNO:this.REGNO,
        SchoolIDNo:this.SchoolIDNo,
        SchoolLogo:this.SchoolLogo.split(',')[1],
        PrincipalSign:this.PrincipalSign.split(',')[1]
                
       };
         this.service.updateSchoolInfo(val).subscribe(res=>{
          this.onModalClose();
          this.showUpdate();
         });
     }

     onModalClose(): void {
      
          this.close.emit();
      
    }
     showSuccess() {
      this.toastr.success('Save Successfully.', '');
   
    }

    showUpdate() {
      this.toastr.success('Update Successfully.', '');
   
    }
  
}
