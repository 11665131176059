import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AcademicsServiceService } from 'src/app/academics-service.service';


@Component({
  selector: 'show-calendar-master',
  templateUrl: './show-calendar-master.component.html',
  styleUrls: ['./show-calendar-master.component.css']
})
export class ShowCalendarMasterComponent implements OnInit {
 
  @ViewChild('closebutton') closebutton;
   CalendarDataList: any[] = [];
  
  NModalTitle:string="";
  ActivateAddEditCalendarComp:boolean=false;
  calendardata:any;
  modalVisible = false;
  p=1;
  filterString = "";
  filtered;
  newdataitem:any;
  constructor(private service:AcademicsServiceService,private modalService:NgbModal,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.refreshCalendarDataList();
  }

  onFilterChange() {
   
        this.filtered = this.CalendarDataList.filter((invoice) => 
         this.isMatch(invoice));
      }
    
      isMatch(item) {
    
        if (item instanceof Object) {
                return Object.keys(item).some((k) => this.isMatch(item[k]));
        } else {
          if(item!=null)
          return item.toString().toUpperCase().indexOf(this.filterString.toUpperCase()) > -1
        }
      }
   onClose() {
    /* debugger
    this.ActivateAddEditSessionComp=false;
    this.refreshSessionList(); */
   // this.closebutton.nativeElement.click();
    
    this.ActivateAddEditCalendarComp=false;
    this.refreshCalendarDataList(); 
    //this.closebutton.nativeElement.click();
    this.modalService.dismissAll();
  
  
  }
  
  addClick(exampleModalContent): void{
    
   
    this.calendardata={
      ID:0,
      Category:"",
      Title:"",
      Description:"",
      StartDate:"",
      EndDate:"",
      Images:"",
      UserName:"",
      Active:"",
      
           
    }
    this.NModalTitle="Add Calendar Data";
    this.ActivateAddEditCalendarComp=true;
    this.modalService.open( exampleModalContent ,{ centered: true, windowClass: 'event-class' });
    
  }
 

closeClick(){
  this.ActivateAddEditCalendarComp=false;
  this.refreshCalendarDataList();
  this.modalService.dismissAll();
}


editClick(item: any,exampleModalContent){

  this.calendardata=item;
  this.NModalTitle = "Edit Calendar";
  this.ActivateAddEditCalendarComp=true;
  this.modalService.open( exampleModalContent ,{ centered: true, windowClass: 'event-class' });

}


  deleteClick(item: any,exampleModalContent){
    this.newdataitem=item;
    this.modalService.open( exampleModalContent, {centered: true } );
    /* 
    if(confirm('Are you sure ??'))
    {
      this.service.deleteSchoolInfo(item.ID).subscribe(data=>
        {
          alert(data.toString());
          this.refreshCalendarDataList();

        });
    }
    */
    
  } 


  deleterecord()
  {

    this.service.deleteCalendarData(this.newdataitem.ID).subscribe(data=>
      {
       //alert(data.toString());
        this.refreshCalendarDataList();
        this.modalService.dismissAll();
this.deleteSuccess();
      });
  
  }
  refreshCalendarDataList(){
    
    this.service.getCalendarDataList().subscribe(data=>{
      this.CalendarDataList=data.sort((a,b)=> new Date(b.ID).getTime() - new Date(a.ID).getTime());
      this.onFilterChange();
    })
  }

  export()
  {
    
  }

  deleteSuccess() {
    this.toastr.success('Delete Successfully.', '');
 
  }
}
