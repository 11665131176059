import { Component, OnInit } from '@angular/core';
import { DataService } from './../../data.service';
@Component({
  selector: 'schoolinfo',
  templateUrl: './schoolinfo.component.html',
  styleUrls: ['./schoolinfo.component.css']
})
export class SchoolinfoComponent implements OnInit {

  constructor(private DataService:DataService) { }

  ngOnInit(): void {
    this.DataService.maintitle="Academics/School Information";
  }
  

}
