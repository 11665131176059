import { DatePipe, formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CheckboxControlValueAccessor, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AcademicsServiceService } from 'src/app/academics-service.service';

@Component({
  selector: 'add-edit-session',
  templateUrl: './add-edit-session.component.html',
  styleUrls: ['./add-edit-session.component.css'],
  providers: [DatePipe]
})
export class AddEditSessionComponent implements OnInit {
  @Input() session:any;
  
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  onModalClose(): void {
 
    //angular.element('.inmodal').hide();
    this.close.emit();
    
  }
  ID?: string ;
  SessionName?:string;
  SessionFrom?:string;
  SessionTo?:string;
  Active?:CheckboxControlValueAccessor;

  
  constructor(private service: AcademicsServiceService,private datePipe: DatePipe,private toastr: ToastrService) { }

  
  ngOnInit(): void {
   
    this.ID=this.session.ID;
    this.SessionName=this.session.SessionName;
     //this.SessionFrom=this.datePipe.transform(this.session.SessionFrom, 'dd/MM/yyyy');
     //this.SessionTo=this.datePipe.transform(this.session.SessionTo, 'dd/MM/yyyy');
     //this.SessionFrom= new Date(this.session.SessionFrom).toISOString().split('T')[0]
     //this.SessionTo= new Date(this.session.SessionTo).toISOString().split('T')[0]
  

     this.SessionFrom= this.datePipe.transform(this.session.SessionFrom, 'yyyy-MM-dd');
     this.SessionTo= this.datePipe.transform(this.session.SessionTo, 'yyyy-MM-dd');
  
     //this.SessionFrom=formatDate(this.session.SessionFrom, 'dd-MM-yyyy', 'en')
    //this.SessionTo=formatDate(this.session.SessionTo, 'dd-MM-yyyy', 'en')
    this.Active=this.session.Active; 
      }

      addSession(){
       
        var val = {ID:this.ID,
        SessionName:this.SessionName,
        SessionFrom:this.SessionFrom,
        SessionTo:this.SessionTo,
        Active:this.Active
      };
        this.service.addSession(val).subscribe(res=>{
          //alert(res.toString());
          this.onModalClose();
          this.showSuccess();
        });
        }
        

        updateSession(){
          var val = {ID:this.ID,
            SessionName:this.SessionName,
            SessionFrom:this.SessionFrom,
        SessionTo:this.SessionTo,
        Active:this.Active
          }
            this.service.updateSession(val).subscribe(res=>{
              //alert(res.toString());
              this.onModalClose();
              this.showUpdate();
            });
        }
        
        showSuccess() {
          this.toastr.success('Save Successfully.', '');
       
        }
    
        showUpdate() {
          this.toastr.success('Update Successfully.', '');
       
        }
}
