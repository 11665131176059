import { Component, OnInit,OnDestroy } from '@angular/core';
import {NgForm} from '@angular/forms'
import { Router } from '@angular/router';
import { timer, Subscription } from "rxjs";
import { Pipe, PipeTransform } from "@angular/core";
import { AuthService } from 'src/app/auth/auth.service';



@Component({
  selector: 'app-otpverify',
  templateUrl: './otpverify.component.html',
  styleUrls: ['./otpverify.component.css']
})


export class OtpverifyComponent implements OnInit, OnDestroy {
  model : any={};
  countDown: Subscription | undefined;
  counter = 10;
  tick = 1000;
  isShown: boolean | undefined;
  constructor(private router: Router,private auth:AuthService) { }


  ngOnInit(): void {
    this.countDown = timer(0, this.tick).subscribe(() => --this.counter);
    
  }
  ngOnDestroy(){
    
     //this.countDown = null;
  }

  
 
  login3(f: NgForm){    
     
    debugger
    if(f.valid)
    {
      
     
        if(this.auth.loginotpno==this.model.OTP)
        
      { 
        this.auth.changepwdflag=true;    
         this.router.navigate(['/changepassword']);
    }
    else
    {
      alert("Enter Wrong OTP");
    }
    }
    else{
      alert("Empty Required Data.");
    }
  }

  
}

@Pipe({
  name: "formatTime"
})
export class FormatTimePipe implements PipeTransform {
  
  constructor(private route:Router){} 
 
  ngOnInit(): void {
   
    
  }


 
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
 


    if(value==-1)
    {
      //alert("OTP Re-Send"); 
      
      this.route.navigate(['/forgotpassword/resendotpform']); // navigate to other page
       
    }
  
    return (
      ("00" + minutes).slice(-2) +
      ":" +
      ("00" + Math.floor(value - minutes * 60)).slice(-2)
     
      
    );
    
  }
  
}
