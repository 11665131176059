import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-sofwaredevelopment',
  templateUrl: './sofwaredevelopment.component.html',
  styleUrls: ['./sofwaredevelopment.component.scss']
})
export class SofwaredevelopmentComponent implements OnInit {
  standard?:string;
  BIRTHDATE?:string;
  L_NAME: any;
  F_NAME: any;
  M_NAME: any;
  Gender:any;
  WhatsAppNo?:string;
  ContactNo?:string;
  ClassName?:string;
  MessageText?:string;
  Comments?:string;
  
  constructor() { }

  ngOnInit(): void {
    //this.inputMessageRef.nativeElement.scrollIntoView();
    //document.querySelector('#target').scrollIntoView({ behavior: 'smooth', block: 'center' });
    document.getElementById("featured-services").scrollIntoView();
  }
  AddEnquiry()
  {

  }
}
