import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  maintitle : string="Dashboard";
  bvalidate : number=0;
  dvalidate : number=0;
  tvalidate : number=0;
 
grquerystring="";

  rowDataClicked1 = {};
  gdata: {};
  edata: {};
  reportcard:any[]=[];
  reportdata: any[];
  acyear="2021-22";

  constructor() { }
}
