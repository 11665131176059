import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-network-system-integration',
  templateUrl: './network-system-integration.component.html',
  styleUrls: ['./network-system-integration.component.scss']
})
export class NetworkSystemIntegrationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    document.getElementById("featured-services").scrollIntoView();
  }

}
