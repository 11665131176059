import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { delay } from 'rxjs/operators';

function _window() : any {
  // return the global native browser window object
  return window;
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {
 
loggedIn: boolean=false;
isLoggedIn$ : Observable<boolean>;
isLoggedOut$ : Observable<boolean>;
 

loginuser: string="fhdfdhfghf";
loginotpno: string="XGHGGX^&";
loginmobile: string="FDFDDFDFD";




loginby:string="X";
changepwdflag:boolean=false;


 logintype:string="xxyytt";
referenceID:number=0;
  
    schoolid:string="TR";

  academicsflag:number=0;
  admissionflag:number=0;
  studentmanagementflag:number=0;
  certificateflag:number=0;
  attendanceflag:number=0;
  employeeflag:number=0;
  feesflag:number=0;
  reportcardflag:number=0;
  payrollflag:number=0;
  libraryflag:number=0;


  academicsmaster:number=0;
  admissionmaster:number=0;
  studentmanagementmaster:number=0;
  certificatemaster:number=0;
  attendancemaster:number=0;
  feesmaster:number=0;
  reportcardmaster:number=0;
  payrollmaster:number=0;
  librarymaster:number=0;


  academicsreport:number=0;
  admissionreport:number=0;
  studentmanagementreport:number=0;
  certificatereport:number=0;
  attendancereport:number=0;
  feesreport:number=0;
  reportcardreport:number=0;
  payrollreport:number=0;
  libraryreport:number=0;
  





  
  clientflag:number=0;
  sprofileflag:number=0;
  profileflag:number=0;
  allotrnoflag:number=0;
  icardflag:number=0;

 
  studentsreportflag:number=0;

  smflag:boolean=false;
  smchildflag:boolean=false;
  
  fmflag:boolean=false;
  fmchildflag:boolean=false;

  fmchequeverification:boolean=false;
  fmfeestructure:boolean=false;
  fmreports:boolean=false;


  prflag:boolean=false;

 
  get nativeWindow() : any {
    return _window();
 }
  constructor() { }

 
  isUserLoggedIn(): boolean {
    return this.loggedIn;
}

  allowchnagepwd(){
    return of(this.changepwdflag).pipe(delay(500));
  }

  allowacademics(){
    return of(this.academicsflag).pipe(delay(500));
  }


  allowsm()
  {
    return of(this.smflag).pipe(delay(500));
  }


  allowchildpermissions()
  {
    return of(true).pipe(delay(500));
  }

}
