import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as AOS from 'aos';
import { ToastrService } from 'ngx-toastr';
import { AcademicsServiceService } from '../academics-service.service';
import { AuthService } from '../auth/auth.service';
import { EmailService } from 'src/app/services/email.service';
import { refresh } from 'aos';
import { element } from 'protractor';
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss','./home-page.component.css'],
  providers: [DatePipe]
})
export class HomePageComponent implements OnInit {
  title = 'demo1';
  @ViewChild('mySelect') input: any;

  model : string;
  ModalTitle:any;
  standard?:string;
  BIRTHDATE?:string;
  L_NAME: any;
  F_NAME: any;
  M_NAME: any;
  Gender:any;
  WhatsAppNo?:string;
  ContactNo?:string;
  Comments?:string;
  ClassName?:string;
  MessageText?:string;
  RELIGION?:string;
  LSNAME?:string;
  LSADDRESS?:string;
  FATHER_Q?:string;
  FATHER_O?:string;
  FATHER_M?:string;
  MOTHER_Q?:string;
  MOTHER_O?:string;
  MOTHER_M?:string;
  ADD_1?:string;
  ADD_2?:string;
  ADD_3?:string;
  TokenNo?:string;
  SectionName?:string;
  FormNo?:string;
  TokenDate?:string;
  Bdate?:any;
  Age?:string;
  StdAsBirth?:string;
  message?:string;

public aFormGroup!: FormGroup;
public siteKey:any;

messagetext="";

AdmConfigList: any[] = [];
AdmClassAssignList: any[] = [];

options = {
  "key": "rzp_test_EQiaPNMIQYIYMB", // Enter the Key ID generated from the Dashboard
  "amount": "50000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
  "currency": "INR",
  "name": "Infant Jesus School",
  "description": "Test Transaction",
  "image": "assets/lexicon/infant_logo.jpg",
  "order_id": "", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
  "prefill": {
      "name": "Rajesh Gawde",
      "email": "rvgawde@gmail.com",
      "contact": "9820171686"
  },
  "notes": {
      "address": "Razorpay Corporate Office"
  },
  "theme": {
      "color": "#3399cc"
  }
};
  constructor(private service: AcademicsServiceService,private datePipe: DatePipe,private router: Router,private modalService:NgbModal,public formBuilder: FormBuilder,private toastr: ToastrService,private http:HttpClient,private auth: AuthService,private emailService:EmailService) {
  
    if(localStorage.getItem('loginhomepage') == "0")
    {
      
      localStorage.setItem('loginhomepage', "1" );
      location.reload();
    }

    
  }

  ngOnInit(): void {
    this.ModalTitle="Enquiry Form"
    AOS.init();
    //this.filladmclasslist();
   
    this.router.navigate(['//home/mainpage'], { skipLocationChange: true });
 
   


  }
  
  onClick(event :Event){
    event.preventDefault();
    this.router.navigate(['//home/mainpage'], { skipLocationChange: true });
 
}



filladmclasslist()
{
  this.service.getAdmClassAssignList().subscribe(data=>{
    this.AdmClassAssignList=data;
  })
}

  addClick(exampleModalContent: any)
  {
        this.modalService.open( exampleModalContent ,{centered: true ,windowClass : "my-class"});
  }

  closeClick()
  {
    this.modalService.dismissAll();
  }
 
  AddEnquiry()
  {

    this.StdAsBirth=this.ClassName;

    this.service.getAdmConfigList().subscribe(data=>{
      this.AdmConfigList=data;
  
      for(var i =0; i <= this.AdmConfigList.length-1 ; i++){
       const category= this.AdmConfigList[i]["TokenCount"]+1;
        this.TokenNo=category;
        this.SectionName="1";   


        var NAsOf  = new Date(this.AdmConfigList[i]["AgeCalcDate"]);
     
        if(this.BIRTHDATE){
         this.Bdate = new  Date (this.BIRTHDATE);
         
   
   
         var clist  = getAge(this.BIRTHDATE);
         var iMonths = clist["years"]*12 + clist["months"];
         this.Age = "Age : "+clist["years"]+"."+clist["months"]+" yrs"
         
             for(var j =0; j <= this.AdmClassAssignList.length-1 ; j++){
                 var agefrom  = this.AdmClassAssignList[j]["AgeFrom"];
                 var ageto  = this.AdmClassAssignList[j]["AgeTo"];
                 if(iMonths>= agefrom && iMonths<= ageto)
                 {
                   this.StdAsBirth=this.AdmClassAssignList[j]["Standard"];
                 }
           
          }
        }
      }
      this.callSaveData();
    })

    }

    callSaveData()
    {
      debugger
      var std = this.ClassName;
      this.TokenDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      this.FormNo="0";
      var val = {ID:0,
        SectionName:this.SectionName,  
        TokenNo:this.TokenNo,
        TokenDate:this.TokenDate,
        FormNo:this.FormNo,
        FName:this.F_NAME,
        LName:this.L_NAME,
        FatherName:this.M_NAME,
        MotherName:"",
        Gender:this.Gender,
        BirthDate:this.BIRTHDATE,
        Age:this.Age,
        MobileNo:this.ContactNo,
        Standard:std,
        StdAsBirth:this.StdAsBirth,
        Category:"R",
        UserName:"Admin",      
    
      };
        this.service.addEnquiryData(val).subscribe(res=>{
          this.modalService.dismissAll();

          this.updateToken();


          var otpno = Math.random().toString().substring(2, 6)
         // this.sendOTP(otpno,"9820171686"); 

          this.messagetext ="Message Sent."
          this.showSuccess();
        });
  
    }

updateToken()
{
  this.service.updateTokenNo(this.TokenNo).subscribe(res=>{
    this.modalService.dismissAll();
  });

}


sendMessage()
{
  let user = {
    name: "",
    email: "rvgawde@gmail.com",
    message: "Trial Mail"
  }
  this.emailService.sendMessage(user).subscribe(
    data => {
      let res:any = data; 
      console.log(
        `👏 > 👏 > 👏 > 👏 ${user.name} is successfully register and mail has been sent and the message id is ${res.messageId}`
      );
    },
    err => {
      console.log(err);
     
    },() => {
      
    }
  );

}


processAmount()
{
  var amount=200
  var rzrvalue= (amount*100)
                this.options["amount"]=rzrvalue.toString();
                this.options["prefill"]["name"]=this.F_NAME+' '+this.L_NAME;
                this.options["prefill"]["contact"]=this.ContactNo;
                let rzp1 = new this.auth.nativeWindow.Razorpay(this.options);
                rzp1.open();

}


    sendOTP(otpvalue:any,mobileno:any): void {
      debugger
      const USER = 'rajeshg';
      const PASS = '456456';
      const mobiles = mobileno;
    
      const RECIPIENT = '+46701234567';
      const SENDER = '+46701234567';
     
      const MESSAGE = 'Your OTP Login is '+otpvalue+'. ESNDSH';
    
      const pid  = "1301159430019830458"
      const tid = "1607100000000103592"
      const fid  = "2"
      const cid  = "0"
      const senderID = "ESNDSH"
 
      const url = `http://myhost:3000/cgi-bin/sendsms?User=${USER}&Pass=${PASS}&To=${RECIPIENT}&From=${SENDER}&Msg=${MESSAGE}`;
      
      const nurl=`https://49.50.67.32/smsapi/httpapi.jsp?username=${USER}&password=${PASS}&from=ESNDSH&to=${mobiles}&text=${MESSAGE}&coding=0&pe_id=${pid}&template_id=${tid}`;
    
      this.http.get(nurl, {responseType: 'text'}).subscribe(
        response => {
          console.log('Got: ' + response);
        },
        error => {
          switch (error.status) {
            case 400:
              console.log('Bad request.' + error.error);
              break;
            case 401:
              console.log(`Your credentials were rejected with HTTP status code ${error.status}. Check and try again.`);
              break;
            case 409:
              console.log('Rate limit problem: ' + error.error);
              break;
            default:
              console.log('Request failed with HTTP status code: ' + error.status);
              break;
          }
        }
      ); 
    
     
    }
  

    showSuccess() {
      this.toastr.success(this.messagetext, "");
   
    }

   

}
function getAge(dateString: any): any {
  
  var now = new Date();
  var today = new Date(now.getFullYear(),now.getMonth(),now.getDate());

  var yearNow = now.getFullYear();
  var monthNow = now.getMonth();
  var dateNow = now.getDate();

  var dob = new Date(dateString.substring(0,4),
                     dateString.substring(5,7)-1,                   
                     dateString.substring(8,10)                  
                     );

  var yearDob = dob.getFullYear();
  var monthDob = dob.getMonth();
  var dateDob = dob.getDate();
  var age = {};
  var ageString = "";
  var yearString = "";
  var monthString = "";
  var dayString = "";


  var yearAge = yearNow - yearDob;

  if (monthNow >= monthDob)
    var monthAge = monthNow - monthDob;
  else {
    yearAge--;
    var monthAge = 12 + monthNow -monthDob;
  }

  if (dateNow >= dateDob)
    var dateAge = dateNow - dateDob;
  else {
    monthAge--;
    var dateAge = 31 + dateNow - dateDob;

    if (monthAge < 0) {
      monthAge = 11;
      yearAge--;
    }
  }

  age = {
      years: yearAge,
      months: monthAge,
      days: dateAge
      };

      
  if ( age["years"] > 1 ) yearString = " years";
  else yearString = " year";
  if ( age["months"]> 1 ) monthString = " months";
  else monthString = " month";
  if ( age["days"] > 1 ) dayString = " days";
  else dayString = " day";


 

   if ( (age["years"] > 0) && (age["months"] > 0) && (age["days"] > 0) )
    ageString = age["years"] + yearString + ", " + age["months"] + monthString + ", and " + age["days"] + dayString + " old.";
  else if ( (age["years"] == 0) && (age["months"] == 0) && (age["days"] > 0) )
    ageString = "Only " + age["days"] + dayString + " old!";
  else if ( (age["years"] > 0) && (age["months"] == 0) && (age["days"] == 0) )
    ageString = age["years"] + yearString + " old. Happy Birthday!!";
  else if ( (age["years"] > 0) && (age["months"] > 0) && (age["days"] == 0) )
    ageString = age["years"] + yearString + " and " + age["months"] + monthString + " old.";
  else if ( (age["years"] == 0) && (age["months"] > 0) && (age["days"] > 0) )
    ageString = age["months"] + monthString + " and " + age["days"] + dayString + " old.";
  else if ( (age["years"] > 0) && (age["months"] == 0) && (age["days"] > 0) )
    ageString = age["years"] + yearString + " and " + age["days"] + dayString + " old.";
  else if ( (age["years"] == 0) && (age["months"] > 0) && (age["days"] == 0) )
    ageString = age["months"] + monthString + " old.";
  else ageString = "Oops! Could not calculate age!";
 
  return age;

}
