import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AcademicsServiceService {
 //readonly APIUrl = "http://localhost:65476/api";
 readonly APIUrl = "http://184.168.120.152:81/api";
 // readonly PhotoUrl = "http://localhost:65476/Photos";
  constructor(private http:HttpClient) { }
  getSessionList():Observable<any[]>
  {
    return this.http.get<any>(this.APIUrl+'/session');
  }
addSession(val:any){
  return this.http.post(this.APIUrl+'/session',val);
}
updateSession(val:any){
  return this.http.put(this.APIUrl+'/session',val);
}

deleteSession(val:any){
    return this.http.delete<number>(this.APIUrl+'/session/'+val);
}

//===============School Info==================
getSchoolInfoList():Observable<any[]>
  {
        return this.http.get<any>(this.APIUrl+'/schoolinfo');
  }
addSchoolInfo(val:any){
  return this.http.post(this.APIUrl+'/schoolinfo',val);
}
updateSchoolInfo(val:any){
  
  return this.http.put(this.APIUrl+'/schoolinfo',val);
}

deleteSchoolInfo(val:any){
    return this.http.delete<number>(this.APIUrl+'/schoolinfo/'+val);
}
//======================Login Data=====================
getLoginData(user:any,pwd:any)
{
 
  return this.http.get<any>(this.APIUrl+'/studentdata?user='+user+'&pwd='+pwd);
}

UpdateLoginData(val:any){
  
  return this.http.put(this.APIUrl+'/studentdata',val);
}


getModuleControl(val:any)
{
  return this.http.get<any>(this.APIUrl+'/schooldata?schoolcode='+val);
}

//======================Class Data=====================

getStdDataList():Observable<any[]>
  {
        return this.http.get<any>(this.APIUrl+'/stdmaster');
  }

getClassDataList():Observable<any[]>
  {
        return this.http.get<any>(this.APIUrl+'/classmaster');
  }
addClassData(val:any){
  return this.http.post(this.APIUrl+'/classmaster',val);
}




getStudentData(qstr:any)
{
 
  return this.http.get<any>(this.APIUrl+'/classdata?qstr='+qstr);
}

updateClassData(val:any){

  return this.http.put(this.APIUrl+'/classmaster',val);
}

AddClassData(val:any[]){
 
  return this.http.post(this.APIUrl+'/classdata',val);
}

deleteClassData(val:any){
    return this.http.delete<number>(this.APIUrl+'/classmaster/'+val);
}


getClassData():Observable<any[]>
{
      return this.http.get<any>(this.APIUrl+'/classdata');
}

getDiscountData():Observable<any[]>
{
      return this.http.get<any>(this.APIUrl+'/discountdata');
}

getStudentDataForFee(acyear:any)
{
 
  return this.http.get<any>(this.APIUrl+'/getstudentdataforfee?acyear='+acyear);
}

getGrDataForLC():Observable<any[]>
{
      return this.http.get<any>(this.APIUrl+'/grdata');
}



getClassStdData(val:any)
{

      return this.http.get<any>(this.APIUrl+'/classmaster?stdname=' + val);
}

getClassMediumData(mediumname:any)
{
  
      return this.http.get<any>(this.APIUrl+'/classmedium?mediumname='+mediumname);
}

getClassStdDivData(std:any,div:any)
{
  return this.http.get<any>(this.APIUrl+'/classmaster?stdname='+std+'&divname='+div);
}
getClassSectionData(sectionname:any)
{
  return this.http.get<any>(this.APIUrl+'/classsection?sectionname='+sectionname);
}
getAttendanceClassData(val:any)
{
  
      return this.http.get<any>(this.APIUrl+'/classdata?qstr=' + val);
}

getAllotRoll(val:any){
 
  return this.http.post(this.APIUrl+'/allotroll',val);
}


//======================Class Teacher================================
getClassTeacherList():Observable<any[]>
{
      return this.http.get<any>(this.APIUrl+'/classteacherdata');
}

updateClassTeacherList(val:any){
  
  return this.http.put(this.APIUrl+'/classteacherdata',val);
}

addClassTeacherList(val:any){
  return this.http.post(this.APIUrl+'/classteacherdata',val);
}

//====================

//======================Notice Board=========================


getNoticeDataList():Observable<any[]>
{
      return this.http.get<any>(this.APIUrl+'/noticeboard');
}
addNoticeData(val:any){
  return this.http.post(this.APIUrl+'/noticeboard',val);
}

updateNoticeData(val:any){
  
  return this.http.put(this.APIUrl+'/noticeboard',val);

}

deleteNoticeData(val:any){
  return this.http.delete<number>(this.APIUrl+'/noticeboard/'+val);
}
//=======================Calendar Master ==============

getCalendarDataList():Observable<any[]>
  {
        return this.http.get<any>(this.APIUrl+'/calendardata');
  }
addCalendarData(val:any){
  return this.http.post(this.APIUrl+'/calendardata',val);
}
updateCalendarData(val:any){
  
  return this.http.put(this.APIUrl+'/calendardata',val);
}

deleteCalendarData(val:any){
    return this.http.delete<number>(this.APIUrl+'/calendardata/'+val);
}

getCalendarDataListByMonth():Observable<any[]>
  {
    
        return this.http.get<any>(this.APIUrl+'/getcalendardata');
  }

//========================Admission==============================

getAdmissionList():Observable<any[]>
  {
        return this.http.get<any>(this.APIUrl+'/admdata');
  }
addAdmission(val:any){
  return this.http.post(this.APIUrl+'/admdata',val);
}
addEnquiryData(val:any){
  return this.http.post(this.APIUrl+'/addenquirydata',val);
}

updateAdmission(val:any){

  return this.http.put(this.APIUrl+'/admdata',val);
}

deleteAdmission(val:any){
    return this.http.delete<number>(this.APIUrl+'/admdata/'+val);
}

getAdmSummaryList():Observable<any[]>
  {
        return this.http.get<any>(this.APIUrl+'/getsummarydata');
  }


  TransferToGr(val:any[]){
 
    return this.http.post(this.APIUrl+'/transferdata',val);
  }

//========================Admission Configuration==============================

getAdmConfigList():Observable<any[]>
  {
    
    return this.http.get<any>(this.APIUrl+'/admconfiguration');
  }

  updateAdmConfiguration(val:any){
  
    return this.http.put(this.APIUrl+'/admconfiguration',val);
  }

  updateTokenNo(val:any){
  
    return this.http.put(this.APIUrl+'/updatetoken',val);
  }

  getTokenIDData():Observable<any[]>
  {
    
    return this.http.get<any>(this.APIUrl+'/gettokendata');
  }

  updateTokenIDData(val:any[]){
 
    return this.http.post(this.APIUrl+'/updatetokendata',val);
  }


//=======================Class Assign==========================
getAdmClassAssignList():Observable<any[]>
{
      return this.http.get<any>(this.APIUrl+'/admclassassign');
}
addClassAssign(val:any){
  return this.http.post(this.APIUrl+'/admclassassign',val);
}
updateClassAssign(val:any){
  
  return this.http.put(this.APIUrl+'/admclassassign',val);
}
deleteClassAssign(val:any){
  return this.http.delete<number>(this.APIUrl+'/admclassassign/'+val);
}

updateClassAssignAll(val:any[]){
 
  return this.http.post(this.APIUrl+'/addperson',val);
}

//============================Fee Structure====================

getFeeStructureByStd(val:any)
{
  
      return this.http.get<any>(this.APIUrl+'/feestructure?stdtext=' + val);
}

getFeeStructure():Observable<any[]>
{
      return this.http.get<any>(this.APIUrl+'/allfeestructure');
}


getFeesHeadData():Observable<any[]>
{
      return this.http.get<any>(this.APIUrl+'/feehead');
}
updateFeeHead(val:any){
  
  return this.http.put(this.APIUrl+'/feehead',val);
}
addFeeHead(val:any){
  return this.http.post(this.APIUrl+'/feehead',val);
}

deleteFeeHead(val:any){
  return this.http.delete<number>(this.APIUrl+'/feedata/'+val);
}

updateFeeStructure(val:any){
  
  return this.http.put(this.APIUrl+'/feestructure',val);
}



addFeeStructureData(val:any){
  return this.http.post(this.APIUrl+'/feestructure',val);
}
deleteFeeStructureData(val:any){
  return this.http.delete<number>(this.APIUrl+'/feestructure/'+val);
}

processFeeData(val:any[] =[]){
  debugger
  return this.http.post(this.APIUrl+'/feedata',val);
}



UpdateConcessionFeeData(val:any[] =[]){
  return this.http.post(this.APIUrl+'/concessionfeedata',val);
}

UpdateConcessionClassData(val:any[] =[]){
  return this.http.post(this.APIUrl+'/classdata',val);
}


getTransferData():Observable<any[]>
{
      return this.http.get<any>(this.APIUrl+'/gettransferdata');
}

//=======================Admission Fee Structure==========================
getAdmFeeList():Observable<any[]>
{
      return this.http.get<any>(this.APIUrl+'/admfeestructure');
}
addAdmFeeStructure(val:any){
  return this.http.post(this.APIUrl+'/admfeestructure',val);
}
updateAdmFeeStructure(val:any){
  
  return this.http.put(this.APIUrl+'/admfeestructure',val);
}
deleteAdmFeeStructure(val:any){
  return this.http.delete<number>(this.APIUrl+'/admfeestructure/'+val);
}

//=======================Admission Receipt Title==========================
getAdmReceiptTitleList():Observable<any[]>
{
      return this.http.get<any>(this.APIUrl+'/admreceipttitle');
}
addAdmReceiptTitle(val:any){
  return this.http.post(this.APIUrl+'/admreceipttitle',val);
}
updateAdmReceiptTitle(val:any){
  
  return this.http.put(this.APIUrl+'/admreceipttitle',val);
}
deleteAdmReceiptTitle(val:any){
  return this.http.delete<number>(this.APIUrl+'/admreceipttitle/'+val);
}

//=======================Admission Question Data==========================
getAdmQuestionList():Observable<any[]>
{
      return this.http.get<any>(this.APIUrl+'/admquestionsdata');
}
addAdmQuestion(val:any){
  return this.http.post(this.APIUrl+'/admquestionsdata',val);
}
updateAdmQuestion(val:any){
  
  return this.http.put(this.APIUrl+'/admquestionsdata',val);
}
deleteAdmQuestion(val:any){
  return this.http.delete<number>(this.APIUrl+'/admquestionsdata/'+val);
}
//=======================Admission Batch==========================
getBatchList():Observable<any[]>
{
      return this.http.get<any>(this.APIUrl+'/admbatchmaster');
}
addBatchData(val:any){
  return this.http.post(this.APIUrl+'/admbatchmaster',val);
}
updateBatchData(val:any){
  
  return this.http.put(this.APIUrl+'/admbatchmaster',val);
}
deleteBatchData(val:any){
  return this.http.delete<number>(this.APIUrl+'/admbatchmaster/'+val);
}
//=======================RefData==========================
getRefData():Observable<any[]>
{
      return this.http.get<any>(this.APIUrl+'/refdata');
}


updateRefData(val:any){
    return this.http.put(this.APIUrl+'/refdata',val);
}


updateRefDataForLC(val:any[] =[]){
  return this.http.post(this.APIUrl+'/refdata',val);
}

getFeeConfig():Observable<any[]>
{
      return this.http.get<any>(this.APIUrl+'/refdata');
}



getLCNO(medium:any,sectext:any)
{
  
      return this.http.get<any>(this.APIUrl+'/getlcdata?medium='+medium+'&sectext='+sectext);
}
//=========================Notice Board Data====================



getNoticeDataByRef(refno:any,classtext:any)
{

  return this.http.get<any>(this.APIUrl+'/NoticeBoardData?refno='+refno+'&classtext='+classtext);
}

//====================Result Data==========================
getDistintSubject(acyear:any,stdname:any)
{
 
  return this.http.get<any>(this.APIUrl+'/stdsubmarks?acyear='+acyear+'&stdname='+stdname);
}



getSubjectMarksData(acyear:any,medium:any,stdname:any,term:any,category:any)
{
 
  return this.http.get<any>(this.APIUrl+'/getsubjectmarksdata?acyear='+acyear+'&medium='+medium+'&stdname='+stdname+'&term='+term+'&category='+category);
}


getResultEntryData(acyear:any,grouptest:any,refno:any)
{
  return this.http.get<any>(this.APIUrl+'/entrydata?acyear='+acyear+'&grouptest='+grouptest+'&refno='+refno);
}
getAllExamData(refno:any)
{
  return this.http.get<any>(this.APIUrl+'/entrydata?refno='+refno);
}

getPerformanceData(acyear:any,grouptest:any,stdname:any,divname:any)
{
  return this.http.get<any>(this.APIUrl+'/entrydata?acyear='+acyear+'&grouptest='+grouptest+'&stdname='+stdname+'&divname='+divname);
}


getSubjectDataList():Observable<any[]>
{
 
  return this.http.get<any>(this.APIUrl+'/subjectmaster');
}

addSubjectData(val:any){
  return this.http.post(this.APIUrl+'/subjectmaster',val);
}
updateSubjectData(val:any){
  return this.http.put(this.APIUrl+'/subjectmaster',val);
}
deleteSubjectData(val:any){
  return this.http.delete<number>(this.APIUrl+'/subjectmaster/'+val);
}


getTestData()
{
   return this.http.get<any>(this.APIUrl+'/gettestgroupdata');
}
getTestNameData()
{
   return this.http.get<any>(this.APIUrl+'/gettestnamedata');
}
getTestDataForTree():Observable<any[]>
{
   return this.http.get<any>(this.APIUrl+'/testdata');
}

addTestData(val:any){
  return this.http.post(this.APIUrl+'/testdata',val);
}

updateTestData(val:any){
  return this.http.put(this.APIUrl+'/testdata',val);
}
deleteTestData(val:any){
  return this.http.delete<number>(this.APIUrl+'/testdata/'+val);
}

updateStdSubTestData(val:any[] =[]){
  return this.http.post(this.APIUrl+'/updatestdsubtestdata',val);
}

alterEntryData(val:any[] =[]){
  return this.http.post(this.APIUrl+'/alterentrydata',val);
}


getTestData2():Observable<any[]>
{
   return this.http.get<any>(this.APIUrl+'/gettestdata2');
}


deleteSubjectMarksData(acyear:any,medium:any,standard:any,term:any,category:any){
  return this.http.delete<number>(this.APIUrl+'/deletesubmarksdata?acyear='+acyear+'&medium='+medium+'&standard='+standard+'&term='+term+'&category='+category);
}


addStdSubMarks(val:any){
 
  return this.http.post(this.APIUrl+'/addsubmarksdata',val);
}

getSubjectData(acyear:any,medium:any,stdname:any,term:any)
{
  return this.http.get<any>(this.APIUrl+'/getsubjectdata?acyear='+acyear+'&medium='+medium+'&stdname='+stdname+'&term='+term);
}


getSubjectTree():Observable<any[]>
{
   return this.http.get<any>(this.APIUrl+'/subjecttree');
}

getTestGroupDistinctData(acyear:any,medium:any,stdname:any,term:any)
{
  return this.http.get<any>(this.APIUrl+'/getdistinctgroup?acyear='+acyear+'&medium='+medium+'&stdname='+stdname+'&term='+term);
}
getTestGroupOrderData(acyear:any,medium:any,stdname:any,term:any)
{
  return this.http.get<any>(this.APIUrl+'/gettestgrouporderdata?acyear='+acyear+'&medium='+medium+'&stdname='+stdname+'&term='+term);
}


getTestData1()
{
   return this.http.get<any>(this.APIUrl+'/gettestdata1');
}

getDistinctTestCodeData(acyear:any,medium:any,stdname:any,term:any)
{
  return this.http.get<any>(this.APIUrl+'/getdistincttestdata?acyear='+acyear+'&medium='+medium+'&stdname='+stdname+'&term='+term);
}

getSubjectPercData()
{
  return this.http.get<any>(this.APIUrl+'/subjectpercdata');
}

getEntryData(acyear:any,medium:any,stdname:any,divname:any,term:any,bbstrEx:any)
{
  return this.http.get<any>(this.APIUrl+'/entrydata?acyear='+acyear+'&medium='+medium+'&stdname='+stdname+'&divname='+divname+'&term='+term+'&bbstrEx='+bbstrEx);
}

getGradeData()
{
   return this.http.get<any>(this.APIUrl+'/grademaster');
}



updateMarksData(val:any[] =[]){
  return this.http.post(this.APIUrl+'/entrydata',val);
}

updatePMarksData(val:any[] =[]){
  return this.http.post(this.APIUrl+'/processentrydata',val);
}


getFinalRemarks(acyear:any,medium:any,stdname:any,divname:any,term:any)
{
  return this.http.get<any>(this.APIUrl+'/finalremarksdata?acyear='+acyear+'&medium='+medium+'&stdname='+stdname+'&divname='+divname+'&term='+term);
}


getRemarksData()
{
   return this.http.get<any>(this.APIUrl+'/remarksdata');
}

getHobbiesData()
{
   return this.http.get<any>(this.APIUrl+'/hobbiesdata');
}
getImprovementData()
{
   return this.http.get<any>(this.APIUrl+'/improvementdata');
}


updateFinalMarksData(val:any[] =[]){
  return this.http.post(this.APIUrl+'/finalremarksdata',val);
}

getEntryDataForRemarks(acyear:any,medium:any,stdname:any,divname:any,term:any)
{
  return this.http.get<any>(this.APIUrl+'/getentrydataforremarks?acyear='+acyear+'&medium='+medium+'&stdname='+stdname+'&divname='+divname+'&term='+term);
}
getDistinctSubject(acyear:any,medium:any,stdname:any,term:any,showSub:any)
{
  return this.http.get<any>(this.APIUrl+'/getdistinctsubject?acyear='+acyear+'&medium='+medium+'&stdname='+stdname+'&term='+term+'&showSub='+showSub);
}



getCheckListData(acyear:any,medium:any,stdname:any,divname:any,term:any,pivotlist:any)
{
  return this.http.get<any>(this.APIUrl+'/checklistdata?acyear='+acyear+'&medium='+medium+'&stdname='+stdname+'&divname='+divname+'&term='+term+'&pivotlist='+pivotlist);
}

getMarksListData(acyear:any,medium:any,stdname:any,divname:any,term:any,pivotlist:any)
{
  return this.http.get<any>(this.APIUrl+'/markslistdata?acyear='+acyear+'&medium='+medium+'&stdname='+stdname+'&divname='+divname+'&term='+term+'&pivotlist='+pivotlist);
}
getSMarksListData(acyear:any,medium:any,stdname:any,divname:any,term:any,pivotlist:any)
{
  return this.http.get<any>(this.APIUrl+'/smarkslistdata?acyear='+acyear+'&medium='+medium+'&stdname='+stdname+'&divname='+divname+'&term='+term+'&pivotlist='+pivotlist);
}


getFinalMarksListData(acyear:any,medium:any,stdname:any,divname:any,term:any,pivotlist:any)
{
  return this.http.get<any>(this.APIUrl+'/finalmarkslistdata?acyear='+acyear+'&medium='+medium+'&stdname='+stdname+'&divname='+divname+'&term='+term+'&pivotlist='+pivotlist);
}

getFinalGradeListData(acyear:any,medium:any,stdname:any,divname:any,term:any,pivotlist:any)
{
  return this.http.get<any>(this.APIUrl+'/finalgradelistdata?acyear='+acyear+'&medium='+medium+'&stdname='+stdname+'&divname='+divname+'&term='+term+'&pivotlist='+pivotlist);
}
getGradeListData(acyear:any,medium:any,stdname:any,divname:any,term:any,pivotlist:any)
{
  return this.http.get<any>(this.APIUrl+'/gradelistdata?acyear='+acyear+'&medium='+medium+'&stdname='+stdname+'&divname='+divname+'&term='+term+'&pivotlist='+pivotlist);
}

getConfigData()
{
  return this.http.get<any>(this.APIUrl+'/configdata');
}

getResultAttendanceData(acyear:any,medium:any,stdname:any,divname:any)
{
  return this.http.get<any>(this.APIUrl+'/resultattendancedata?acyear='+acyear+'&medium='+medium+'&std='+stdname+'&div='+divname);
}



getReportCardData(acyear:any,medium:any,stdname:any,divname:any,term:any,showSub)
{
  return this.http.get<any>(this.APIUrl+'/dataforreportcard?acyear='+acyear+'&medium='+medium+'&stdname='+stdname+'&divname='+divname+'&term='+term+'&showSub='+showSub);
}

getTermAttendMonthData()
{
   return this.http.get<any>(this.APIUrl+'/termattendmonth');
}

getResultFormatData()
{
  return this.http.get<any>(this.APIUrl+'/resultformat');
}

deleteResultFormatData(){
  return this.http.delete<number>(this.APIUrl+'/resultformat');
}

addResultFormatData(val:any[] =[]){
  return this.http.post(this.APIUrl+'/resultformat',val);
}

getResultConfig()
{
  return this.http.get<any>(this.APIUrl+'/resultconfigdata');
}

updateResultConfig(val:any){
  return this.http.put(this.APIUrl+'/resultconfigdata',val);
}


getDistinctSubjectData(acyear:any,medium:any,stdname:any)
{
  return this.http.get<any>(this.APIUrl+'/getdistinctsubjectdata?acyear='+acyear+'&medium='+medium+'&stdname='+stdname);
}

getDistinctCategorySubjectData(acyear:any,medium:any,stdname:any)
{
  return this.http.get<any>(this.APIUrl+'/getdistinctcategorysubject?acyear='+acyear+'&medium='+medium+'&stdname='+stdname);
}


updateResultAttendance(val:any[] =[]){
  return this.http.post(this.APIUrl+'/resultattendancedata',val);
}


fillEntryData(val:any[] =[]){
  return this.http.post(this.APIUrl+'/entrydata',val);
}

//=========================================================
//=========================Employee Data===========================

getEmployeeData():Observable<any[]>
{
      return this.http.get<any>(this.APIUrl+'/employeemaster');
}

getEmployeeDataByID(refno:any)
{
 
  return this.http.get<any>(this.APIUrl+'/employeemaster?refno='+refno);
}

AddEmployeeData(val:any){
  return this.http.post(this.APIUrl+'/employeemaster',val);
}

updateEmployeeData(val:any){
  return this.http.put(this.APIUrl+'/employeemaster',val);
}

deleteEmployeeData(val:any){
  return this.http.delete<number>(this.APIUrl+'/employeemaster/'+val);
}

getEmpRefData():Observable<any[]>
{
      return this.http.get<any>(this.APIUrl+'/employeemaster');
}

getSalaryTypeData():Observable<any[]>
{
      return this.http.get<any>(this.APIUrl+'/getsalarytypedata');
}

getSalaryTypeDetailsData(stype:any)
{
      return this.http.get<any>(this.APIUrl+'/salarytypedetailsdata?stype='+stype);
}

getQualificationData(refno:any)
{
      return this.http.get<any>(this.APIUrl+'/employeequalification?eid='+refno);
}

addQualification(val:any){
  return this.http.post(this.APIUrl+'/employeequalification',val);
}
deleteQualification(val:any){
  return this.http.delete<number>(this.APIUrl+'/employeequalification/'+val);
}

updateQualification(val:any){
  
  return this.http.put(this.APIUrl+'/employeequalification',val);
}


getDesignationData():Observable<any[]>
{
      return this.http.get<any>(this.APIUrl+'/designationdata');
}


getFacultyDataByName(fname:any)
{
      return this.http.get<any>(this.APIUrl+'/facultysubjectdata?fname='+fname);
}

addFacultySubjectData(val:any){
  return this.http.post(this.APIUrl+'/facultysubjectdata',val);
}

deleteFacultySubject(val:any){
  return this.http.delete<number>(this.APIUrl+'/facultysubjectdata/'+val);
}
updateFacultyData(val:any){
  return this.http.put(this.APIUrl+'/facultysubjectdata',val);
}




//=======================Admission Batch==========================
getGrData():Observable<any[]>
{
      return this.http.get<any>(this.APIUrl+'/grdata');
}

getGrDataByQuery(qstring:any)
{
 
      return this.http.get<any>(this.APIUrl+'/grdata?qstring='+qstring);
}

getGrStdDivData(std:any,div:any,grfrom:any,grto:any)
{
  return this.http.get<any>(this.APIUrl+'/grdata?stdname='+std+'&divname='+div+'&grfrom='+grfrom+'&grto='+grto);
}

AddGrData(val:any){
  return this.http.post(this.APIUrl+'/grdata',val);
}

AddCompleteGrData(val:any){
  return this.http.post(this.APIUrl+'/postgrdata',val);
}

updateCompleteGrData(val:any){
  return this.http.put(this.APIUrl+'/grdata',val);
}

deleteGrData(val:any){
  return this.http.delete<number>(this.APIUrl+'/grdata/'+val);
}



getReferenceData():Observable<any[]>
{
  
  return this.http.get<any>(this.APIUrl+'/getreferencedata');
}

//=======================Attendance Data==========================
getAbsentData():Observable<any[]>
{
      return this.http.get<any>(this.APIUrl+'/getabsentdata');
}

getTeacherAtt(classtext:any)
{
  
  return this.http.get<any>(this.APIUrl+'/teacheratt?classname='+classtext);
}
getStudentAtt(refno:any)
{
  
  return this.http.get<any>(this.APIUrl+'/studentatt?refno='+refno);
}

getTeacherAttendance(yr1:any,yr2:any,classtext:any)
{
  
  return this.http.get<any>(this.APIUrl+'/attendancedata?yr1='+yr1+'&yr2='+yr2+'&classtext='+classtext);
}


getStudentAttendance(yr1:any,yr2:any,refno:any)
{
  return this.http.get<any>(this.APIUrl+'/getattenddata?yr1='+yr1+'&yr2='+yr2+'&refno='+refno);
}


getRnoData():Observable<any[]>
{
      return this.http.get<any>(this.APIUrl+'/rnodata');
}

getCasteData():Observable<any[]>
{
      return this.http.get<any>(this.APIUrl+'/getcastedata');
}

getAttendanceEntryData(stdstr:any,divstr:any,mon:any,yr:any)
{
  return this.http.get<any>(this.APIUrl+'/getattendanceentrydata?stdstr='+stdstr+'&divstr='+divstr+'&monthstr='+mon+'&yearstr='+yr);
}

getAttendanceEntryDataByClass(stdstr:any,divstr:any)
{
  return this.http.get<any>(this.APIUrl+'/attendanceentry?stdstr='+stdstr+'&divstr='+divstr);
}


getAttendanceDataByDate(classstr:any,monthstr:any,yearstr)
{
  return this.http.get<any>(this.APIUrl+'/getattenddatabydate?classstr='+classstr+'&monthstr='+monthstr+'&yearstr='+yearstr);
}


updateTeacherAtteandance(val:any[] =[]){
  return this.http.post(this.APIUrl+'/teacherattendance',val);
}

deleteAttendance(val:any[] =[]){
  return this.http.post(this.APIUrl+'/attendancedata',val);
}

updateAttendanceEntry(val:any[] =[]){
  return this.http.post(this.APIUrl+'/attendanceentry',val);
}


//=======================Fee Data=========================

getAccountData():Observable<any[]>
{
      return this.http.get<any>(this.APIUrl+'/accountdata');
}
getArrears(val:any)
{
      return this.http.get<any>(this.APIUrl+'/arrears?refno='+val);
}

getAllArrears()
{
      return this.http.get<any>(this.APIUrl+'/arrears');
}
getFeeDataByRef(totalstr:any,paidstr:any)
{
  return this.http.get<any>(this.APIUrl+'/feedata?totalstr='+totalstr+'&paidstr='+paidstr);
}

getOutsDataList(val1:any,val2:any,val3:any,pstatus:any)
{
 
  return this.http.get<any>(this.APIUrl+'/getoutsdata?val1='+val1+'&val2='+val2+'&val3='+val3+'&pstatus='+pstatus);
}

getCancelReceiptData():Observable<any[]>
{
      return this.http.get<any>(this.APIUrl+'/cancelreceiptdata');
}

getPaidData(val:any, facyear:any)
{
      return this.http.get<any>(this.APIUrl+'/cashregister?refno='+val+'&facyear='+facyear);
}

getCashRegisterData(qstr:any)
{
 
  return this.http.get<any>(this.APIUrl+'/cashregister?qstr='+qstr);
}

getMasterData(qstr:any)
{
 
  return this.http.get<any>(this.APIUrl+'/master?qstr='+qstr);
}



getFeeData(val:any, facyear:any)
{
      return this.http.get<any>(this.APIUrl+'/feedata?refno='+val+'&facyear='+facyear);
}

getConcessionFeeData(val:any)
{
      return this.http.get<any>(this.APIUrl+'/feedata?qstr='+val);
}
addCancelReceipt(val:any[] =[]){
  return this.http.post(this.APIUrl+'/cancelreceiptdata',val);
}


getDueDateData(med:any,sec:any,facyear:any)
{
      return this.http.get<any>(this.APIUrl+'/duedatedata?med='+med+'&sec='+sec+'&facyear='+facyear);
}

getRPTData(med:any,sec:any)
{
      return this.http.get<any>(this.APIUrl+'/rpt?med='+med+'&sec='+sec);
}

getERPTData():Observable<any[]>
{
      return this.http.get<any>(this.APIUrl+'/erpt');
}


/* updateMaster(val:any){
    return this.http.post(this.APIUrl+'/master',val);
} */

updateMaster(val:any[] =[]){
  return this.http.post(this.APIUrl+'/master',val);
}

/* updateCashRegister(val:any){

  return this.http.post(this.APIUrl+'/cashregister',val);
} */

updateCashRegister(val:any[] =[]){
  return this.http.post(this.APIUrl+'/cashregister',val);
}

/* updateFeeData(val:any){
  return this.http.put(this.APIUrl+'/feedata',val);
} */

updateFeeData(val:any[] =[]){
  return this.http.post(this.APIUrl+'/updatefeedata',val);
}


updateCancelFeeData(val:any[] =[]){
  return this.http.post(this.APIUrl+'/updatefeedata',val);
}

deleteMasterData(val:any[] =[]){
  return this.http.post(this.APIUrl+'/deletemasterdata',val);
}

deleteCashData(val:any[] =[]){
  return this.http.post(this.APIUrl+'/deletecashdata',val);
}



updateRPTData(val:any){
  return this.http.put(this.APIUrl+'/rpt',val);
}

updateERPTData(val:any){
  return this.http.put(this.APIUrl+'/erpt',val);
}


getChequeVerificationData(aname:any,dt1:any,dt2:any)
{
      return this.http.get<any>(this.APIUrl+'/chequedatadetails?aname='+aname+'&dt1='+dt1+'&dt2='+dt2);
}

updateChequeDataDetails(val:any){
  return this.http.put(this.APIUrl+'/chequedatadetails',val);
}


getReceiptNo(val:any[] =[]){
  return this.http.post(this.APIUrl+'/rpt',val);
}

//======================Assignment Data================

getAssignmentData(classname:any)
{
      return this.http.get<any>(this.APIUrl+'/assignmentdata?classname='+classname);
}

getAssignmentList(refno:any)
{
      return this.http.get<any>(this.APIUrl+'/assignmentlistdata?refno='+refno);
}

getSubmittedList(asgcode:any)
{
      return this.http.get<any>(this.APIUrl+'/submissiondata?asgcode='+asgcode);
}

getAssignmentListByrefno(refno:any,logintype:any,assignclass:any)
{
      return this.http.get<any>(this.APIUrl+'/assignmentlistdatabyrefno?refno='+refno+'&logintype='+logintype+'&assignclass='+assignclass);
}

AddNewAssignment(val:any[] =[]){
  return this.http.post(this.APIUrl+'/assignmentdata',val);
}




//===========================Dashboard Data=============

getDashboardData():Observable<any[]>
{
      return this.http.get<any>(this.APIUrl+'/dashboarddata');
}


getStudentListData(stdname:any,divname:any)
{
      return this.http.get<any>(this.APIUrl+'/getclassdata?stdname='+stdname+'&divname='+divname);
}

}



