import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ToastrService } from 'ngx-toastr';
import { AcademicsServiceService } from 'src/app/academics-service.service';

@Component({
  selector: 'app-add-edit-notices',
  templateUrl: './add-edit-notices.component.html',
  styleUrls: ['./add-edit-notices.component.scss'],
  providers: [DatePipe]
})
export class AddEditNoticesComponent implements OnInit {
  @Input() calendardata:any;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  ID?:string;
  Category?:string;
  Title?:string;
  Description?:string;
  NoticeDate?:string;

  Images?:string;
 
  Active?:Boolean;
  status ?: string;
  
  public message: string;
  public imagePath;
  imgURL: any;
  imgURL1: any;
  selectedFile;


activeList: any = ['Y', 'N']
actName: any;
  constructor(private service: AcademicsServiceService,private imageCompress: NgxImageCompressService,private datePipe: DatePipe,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.ID=this.calendardata.ID;
    this.Title=this.calendardata.Title;
     this.Description=this.calendardata.Description;
    
    this.NoticeDate= this.datePipe.transform(this.calendardata.NoticeDate, 'yyyy-MM-dd');
 
     this.Images = "data:image/png;base64,"+ this.calendardata.Images
   
    this.Active=this.calendardata.Active;
  }

  

  changeActive(e) {
    this.actName=e.target.value
      onlySelf: true
    
  }

  
   
    onFileChanged(event) {
    
    this.selectedFile = event.target.files[0]
    const reader = new FileReader();
    reader.readAsDataURL(this.selectedFile);
    reader.onload = () => {
        console.log(reader.result);
        this.imgURL=reader.result;
        this.Images= this.imgURL;

     /*   this.imageCompress.compressFile(this.imgURL, 0,256,158).then(
          result => {
            console.log(result);
           
            this.Images=result;
            console.warn('Size in bytes is now:', this.imageCompress.byteCount(result));
          }
        );   */
        //this.SchoolLogo=this.imgURL;
       
    };
  } 


  addCalendarData(){
  
    var val = {ID:this.ID,
    Title:this.Title,
    Description:this.Description,
    NoticeDate:this.NoticeDate,
      Images:this.Images.split(',')[1],
  
    Active:this.Active
   

        
  };
 
    this.service.addNoticeData(val).subscribe(res=>{
      //alert(res.toString());

      this.onModalClose();
      this.showSuccess();
    });
    }
    

    updateCalendarData(){
     
 
     var val = {ID:this.ID,
             Title:this.Title,
       Description:this.Description,
       NoticeDate:this.NoticeDate,
       Images:this.Images.split(',')[1],
    Active:this.Active
    
               
      };
    
        this.service.updateNoticeData(val).subscribe(res=>{
          this.onModalClose();
          this.showUpdate();
        });
    }

    onModalClose(): void {
      
      //angular.element('.inmodal').hide();
      this.close.emit();
      
    }

    showSuccess() {
      this.toastr.success('Save Successfully.', '');
   
    }

    showUpdate() {
      this.toastr.success('Update Successfully.', '');
   
    }

}
