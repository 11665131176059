import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms'
import { Router } from '@angular/router';

@Component({
  selector: 'app-resendotpform',
  templateUrl: './resendotpform.component.html',
  styleUrls: ['./resendotpform.component.css']
})
export class ResendotpformComponent implements OnInit {
  model : any={};
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  login4(f: NgForm){    
    if(f.valid)
    {
      
    }
    else{
      alert("Empty Required Data.");
    }
  }
}
