import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnInit {

  constructor(private location: Location) { }

  ngOnInit(): void {
  }
  changeURL(){
    this.location.replaceState('//home');
    
  }
}
