import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AcademicsServiceService } from 'src/app/academics-service.service';

@Component({
  selector: 'show-schoolinfo',
  templateUrl: './show-schoolinfo.component.html',
  styleUrls: ['./show-schoolinfo.component.css']
})
export class ShowSchoolinfoComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  
  SchoolInfoList: any[] = [];
  
  NModalTitle:string="";
  ActivateAddEditSchoolInfoComp:boolean=false;
  schoolinfo:any;
  modalVisible = false;
  newdataitem:any;
  constructor(private service:AcademicsServiceService,private modalService:NgbModal) { }

  ngOnInit(): void {
    this.refreshSchoolInfoList();
  }

  onClose(){
  
    this.ActivateAddEditSchoolInfoComp=false;
    this.refreshSchoolInfoList();
    this.modalService.dismissAll();

  
   }

  
  addClick(exampleModalContent): void{
    
    this.schoolinfo={
      ID:0,
      MEDIUM:"",
      SECTIONTYPE:"",
      HEAD:"",
      ADDRESS:"",
      REGNO:"",
      SchoolIDNo:"",
      SchoolLogo:"",
      PrincipalSign:"",
           
    }
    this.NModalTitle="Add School Info";
    this.ActivateAddEditSchoolInfoComp=true;
    this.modalService.open( exampleModalContent ,{ centered: true, windowClass: 'new-class' });
  }
 

closeClick(){
  this.ActivateAddEditSchoolInfoComp=false;
  this.refreshSchoolInfoList();
  this.modalService.dismissAll();
}


editClick(item: any,exampleModalContent){
 
  this.schoolinfo=item;
  this.NModalTitle = "Edit School Info";
  this.ActivateAddEditSchoolInfoComp=true;
  this.modalService.open( exampleModalContent ,{ centered: true, windowClass: 'new-class' });
}




  deleteClick(item: any,exampleModalContent){
   
    this.newdataitem=item;
    this.modalService.open( exampleModalContent, {centered: true } );

  /*   if(confirm('Are you sure ??'))
    {
      this.service.deleteSchoolInfo(item.ID).subscribe(data=>
        {
          alert(data.toString());
          this.refreshSchoolInfoList();

        });
    } */
   
    
  } 


  deleterecord()
  {
    this.service.deleteSchoolInfo(this.newdataitem.ID).subscribe(data=>
      {
        //alert(data.toString());
        this.refreshSchoolInfoList();
        this.modalService.dismissAll();
      });
  }

  refreshSchoolInfoList(){
    
    this.service.getSchoolInfoList().subscribe(data=>{
      this.SchoolInfoList=data;
    })
  }
}
