import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { relativeTimeThreshold } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AcademicsServiceService } from 'src/app/academics-service.service';
import { DataService } from './../../data.service';

@Component({
  selector: 'classinfo',
  templateUrl: './classinfo.component.html',
  styleUrls: ['./classinfo.component.css']
})
export class ClassinfoComponent implements OnInit {

  classdataList: Array<any> = [];
  awaitingClassList: Array<any> = [];
  editField: string;
  newdataitem: any;
  edit=true;
  save=false;
  Medium:any;
  Section1:any;
  Standard:any;
  Mnemonic:any;
coursedata:any;
ModalTitle:string="";
flag=0;
alertmessage:string;
ID:any;
  constructor(private service:AcademicsServiceService,private DataService:DataService,private modalService:NgbModal,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.DataService.maintitle="Academics/Class Information";
//this.awaitingClassList.push("ID=0,Medium:'',Section1='',Standard='',Mnemonic=''");

    /* this.awaitingClassList[0]["ID"] = 0;
    this.awaitingClassList[0]["Medium"] = "";
    this.awaitingClassList[0]["Section1"] = "";
    this.awaitingClassList[0]["Standard"] = "";
    this.awaitingClassList[0]["Mnemonic"] = "";
 */
    
    this.refreshClassDataList();
  }

  refreshClassDataList(){
    this.editField="";
    this.service.getStdDataList().subscribe(data=>{
      data = data.sort((a, b) => (a.ID > b.ID ? -1 : 1));
      this.classdataList=data;
      
    })
  }

  updateList() {

   // const editField = event.target.textContent;
    //this.classdataList[mid][property]= editField;

    var val = {ID:this.ID,
      Medium:this.Medium,
      Section1:this.Section1,
      Standard:this.Standard,
      Mnemonic:this.Mnemonic
             
     };

    this.service.updateClassData(val).subscribe(res=>{
      this.refreshClassDataList();
      this.modalService.dismissAll();
      this.alertmessage="Update Successfully.";
          this.showSuccess();
});


  }

  deleterecord() {
    /* this.awaitingPersonList.push(this.personList[id]);
    this.personList.splice(id, 1); */

    
      this.service.deleteClassData( this.newdataitem.ID).subscribe(data=>
        {
          //alert(data.toString());
          this.refreshClassDataList();
          this.modalService.dismissAll();
          this.alertmessage="Delete Successfully.";
          this.showSuccess();
        });
    
   
  }


  deleteClick(item: any,smallModalContent){
    this.newdataitem=item;
    this.modalService.open( smallModalContent, {centered: true } );
  }
  

  add() {
    //if (this.awaitingClassList.length > 0) {
      //const person = this.awaitingClassList[0];

      var val = {ID:0,
        Medium:this.Medium,
        Section1:this.Section1,
        Standard:this.Standard,
        Mnemonic:this.Mnemonic
            
      };
        this.service.addClassData(val).subscribe(res=>{
     
          this.refreshClassDataList();
          this.modalService.dismissAll();

          this.alertmessage="Add Successfully.";
          this.showSuccess();
        });

      
      
   // }
  }

  closeClick()
  {
    this.modalService.dismissAll();
  }

  addClick(exampleModalContent)
  {


  
      this.ID=0,
      this.Medium="",
      this.Section1=  "",
      this.Standard="",
      this.Mnemonic=""
     
  
    this.ModalTitle="Add Course";
    this.modalService.open( exampleModalContent ,{ centered: true, windowClass: 'modal-rounded' });
  }


  editClick(item: any,exampleModalContent){

    this.coursedata=item;

    this.ID=this.coursedata.ID;
    this.Medium=this.coursedata.Medium;
    this.Section1=this.coursedata.Section1;
    this.Standard=this.coursedata.Standard;
    this.Mnemonic=this.coursedata.Mnemonic;


    this.ModalTitle = "Edit Course";
    this.modalService.open( exampleModalContent ,{ centered: true, windowClass: 'modal-rounded' });
  }

  
  changeValue(mid: number, property: string, event: any) {
   
    this.editField = event.target.textContent;
    //this.classdataList[mid][property] = this.editField;
  }



  showSuccess() {
    this.toastr.success(this.alertmessage, '');
 
  }

 
}


