import { AuthService } from 'src/app/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import * as $ from "jquery";
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public uiBasicCollapsed = false;
  public samplePagesCollapsed = false;

  public uiAcademicsCollapsed = false;
  public uiAdmissionCollapsed=false;
  public uiAdmissionReportCollapsed=false;
  public uiAdmissionMasterCollapsed = false;
  public uiTeachersCollapsed=false;
  public uiTeachersReportCollapsed=false;
public uiStudentsCollapsed=false;
public uiStudentsCertifCollapsed=false;
public uiStudentsReportCollapsed=false;
public uiFeesCollapsed=false;
public uiFeesMasterCollapsed=false;
public uiFeesReportCollapsed=false;
public uiPayrollCollapsed=false;
public uiPayrollMasterCollapsed=false;
public uiPayrollReportCollapsed=false;
public uiExamCollapsed=false;
public uiExamMasterCollapsed=false;
public uiExamReportCollapsed=false;
public uiLibraryCollapsed=false;
public uiLibraryMasterCollapsed=false;
public uiLibraryReportCollapsed=false;
lgtype:any;








visibleStatus=false;


currentPage=1;
isDisabled=false;
  refimage:any;
username:any;
usertype:any;
academicsVisible: boolean = false;
  constructor(private auth:AuthService) {



   }

  ngOnInit() {

    if(localStorage.getItem("logintype")=="Admin")
this.lgtype="admin"
else
if(localStorage.getItem("logintype")=="Teacher")
this.lgtype = "teacher"
else
if(localStorage.getItem("logintype")=="Student")
this.lgtype = "student"
else
this.lgtype = ""

if(this.lgtype=="admin")
{
  this.visibleStatus=true;
    this.auth.academicsflag=1;
    this.auth.admissionflag=1;
    this.auth.studentmanagementflag=1;
    this.auth.certificateflag=1;
    this.auth.attendanceflag=1;
    this.auth.employeeflag=1;
    this.auth.feesflag=1;
    this.auth.reportcardflag=1;
    this.auth.payrollflag=1;
    this.auth.libraryflag=1;
  
  
    this.auth.academicsmaster=1;
    this.auth.admissionmaster=1;
    this.auth.studentmanagementmaster=1;
    this.auth.certificatemaster=1;
    this.auth.attendancemaster=1;
    this.auth.feesmaster=1;
    this.auth.reportcardmaster=1;
    this.auth.payrollmaster=1;
    this.auth.librarymaster=1;

    this.auth.academicsreport=1;
    this.auth.admissionreport=1;
    this.auth.studentmanagementreport=1;
    this.auth.certificatereport=1;
    this.auth.attendancereport=1;
    this.auth.feesreport=1;
    this.auth.reportcardreport=1;
    this.auth.payrollreport=1;
    this.auth.libraryreport=1;
}
else
{
  if(this.lgtype=="teacher")
  {
    this.visibleStatus=false;
    this.auth.academicsflag=0;
    this.auth.admissionflag=0;
    this.auth.studentmanagementflag=1;
    this.auth.certificateflag=0;
    this.auth.attendanceflag=1;
    this.auth.employeeflag=1;
    this.auth.feesflag=0;
    this.auth.reportcardflag=1;
    this.auth.payrollflag=0;
    this.auth.libraryflag=0;
  
  
    this.auth.academicsmaster=0;
    this.auth.admissionmaster=0;
    this.auth.studentmanagementmaster=0;
    this.auth.certificatemaster=0;
    this.auth.attendancemaster=0;
    this.auth.feesmaster=0;
    this.auth.reportcardmaster=0;
    this.auth.payrollmaster=0;
    this.auth.librarymaster=0;



    this.auth.academicsreport=0;
    this.auth.admissionreport=0;
    this.auth.studentmanagementreport=0;
    this.auth.certificatereport=0;
    this.auth.attendancereport=0;
    this.auth.feesreport=0;
    this.auth.reportcardreport=0;
    this.auth.payrollreport=0;
    this.auth.libraryreport=0;


  }
}



    this.refimage="assets/lexicon/photos/"+localStorage.getItem("referenceID")+".jpg";
   
    this.usertype=localStorage.getItem("logintype");
    this.username=localStorage.getItem("username");



        
        if(Number(localStorage.getItem("academicsflag"))==0)
        this.academicsVisible=false;
        else
        this.academicsVisible=true;

    const body = document.querySelector('body');

    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll('.sidebar .nav-item').forEach(function (el) {
     
    
      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
      
    });

 /*    $( "#foo" ).bind( "click", function() {
      alert( "User clicked on 'foo.'" );
    }); */
  }


  toggleMainPanel()
  {
  
    /*   $("#sidebar").animate({ scrollTop: 30 }, "slow");
  window.scrollTo(0,0);
      alert("wait"); */

      $("html, body").animate({ scrollTop: 0 }, "slow");
  }

  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
}
