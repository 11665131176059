import { Component, OnInit } from '@angular/core';
import { DataService } from './../../data.service';
@Component({
  selector: 'calendar-master',
  templateUrl: './calendar-master.component.html',
  styleUrls: ['./calendar-master.component.css']
})
export class CalendarMasterComponent implements OnInit {

  constructor(private DataService:DataService) { }

  ngOnInit(): void {
    this.DataService.maintitle="Academics/Calendar Information";
  }

}
